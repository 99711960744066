import React, { useEffect, useState } from "react";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  PlusCircleTwoTone,
  ReloadOutlined,
  SyncOutlined,
  UpCircleFilled,
  UpCircleTwoTone,
} from "@ant-design/icons";
import { MockupStore } from "../store/Mockup.store";
import mockupGeneratorService from "../../services/mockupGenerator.service";
import ReactMarkdown from "react-markdown";
import { ConfigProvider, Input, Popconfirm, Popover, Skeleton } from "antd";
import { userInfo } from "os";
import TextArea from "antd/es/input/TextArea";

const UserStory = () => {
  const [collapseControllers, setCollapseControllers] = useState<boolean[]>([]),
    [response, setResponse] = useState<any>([]);
  const [userStoryResponseStatus, setUserStoryResponseStatus] =
      useState<any>(false),
    [promptValue, setPromptValue] = useState<string>("");
  const [testCaseVisible, setTestCaseVisible] = useState({
    userStoryId: 0,
    testCaseVisible: false,
  });
  const [acceptanceCriteriaVisible, setAcceptanceCriteriaVisible] = useState({
    userStoryId: 0,
    acceptanceCriteriaVisible: false,
  });
  const [newUserStoryValue, setNewUserStoryValue] = useState<string>("");
  const [newUserStoryVisable, setNewUserStoryVisable] = useState(false);
  const [isTestCaseEditable, setIsTestCaseEditable] = useState(0),
    [isAcceptanceCriteriaEditable, setIsAcceptanceCriteriaEditable] =
      useState(0),
      [isUserStoryEditable,setIsUserStoryEditable]=useState(0);
  const [testCaseChangeValue, setTestCaseChangeValue] = useState<string>(),
    [acceptanceCriteriaChangeValue, setAcceptanceCriteriaChangeValue] =
      useState<string>(),
      [userStoryChangeValue,setUserStoryChangeValue]=useState("");
  const {
    projectPageLists,
    setProjectPageLists,
    activePageId,
    setRequirementApiStatus,
    requirementApiStatus,
  } = MockupStore((state: any) => state);

  // const content = (
  //   <div>
  //    <Input size="large" style={{ width: '100%' }} onChange={(e)=>{setPromptValue(e.target.value)}} suffix={<UpCircleTwoTone className='sendPromptIcon' onClick={handlePrompt}/>}/>
  //   </div>
  // );

  const handleUserStory = async () => {
    setCollapseControllers([]);
    setResponse([]);

    if (activePageId) {
      setRequirementApiStatus(true);
      try {
        const formData = new FormData();
        formData.append(
          "request_object",
          JSON.stringify({
            project_page_id: activePageId,
            input_text: "",
          })
        );

        const response: any = await mockupGeneratorService.generateUserStory(
          formData
        );
        const tempUserStoryArray: any = [];
        response.data.saved_user_stories.map((e: any) => {
          tempUserStoryArray.push({
            userStory: e,
            acceptanceCriteria: "",
            testCases: "",
          });
        });
        setResponse(tempUserStoryArray);
        const acceptancePromises = tempUserStoryArray.map(
          async (e: any, index: number) => {
            await handleAcceptanceCriteria(
              e.userStory.user_story,
              e.userStory.user_story_id,
              index
            );
          }
        );

        await Promise.all(acceptancePromises);
        setRequirementApiStatus(false);
      } catch (error) {
        console.log(error);
      } finally {
        setUserStoryResponseStatus(true);
      }
    }
  };

  const handleAcceptanceCriteria = async (
    userStoryValue: string,
    userStoryId: number,
    index: number
  ) => {
    setUserStoryResponseStatus(false);
    promptValue &&
      setAcceptanceCriteriaVisible({
        userStoryId: 0,
        acceptanceCriteriaVisible: false,
      });

    let codeChunks = "";
    try {
      const payload = {
        user_story: userStoryValue,
        project_page_id: activePageId,
        user_input: promptValue,
        user_story_id: userStoryId,
      };
      const response: any = await mockupGeneratorService.generateAcceptance(
        payload
      );
      console.log(response.body);
      //console.log("hi",response.body.data.getReader())
      const reader1: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      while (true) {
        const result1: any = await reader1?.read();
        console.log(result1);
        if (result1) {
          const { done, value } = result1;
          if (done) {
            !promptValue &&
              (await handleTestCase(codeChunks, userStoryId, index));
            break;
          }
          codeChunks += new TextDecoder().decode(value);
          console.log("codeChunks", codeChunks);
          setResponse((prevState: any) => {
            const updatedState = [...prevState];
            updatedState[index] = {
              ...updatedState[index],
              acceptanceCriteria: codeChunks,
            };
            return updatedState;
          });
        }
      }
    } catch {
    } finally {
      setPromptValue("");
    }
  };

  const handleTestCase = async (
    acceptanceCriteriaValue: string,
    userStoryId: number,
    index: number
  ) => {
    promptValue &&
      setTestCaseVisible({ userStoryId: 0, testCaseVisible: false });
    try {
      let codeChunks = "";
      const payload = {
        acceptance_criteria: acceptanceCriteriaValue,
        project_page_id: activePageId,
        user_input: promptValue,
        user_story_id: userStoryId,
      };
      const response: any = await mockupGeneratorService.generateTestCases(
        payload
      );
      const reader1: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      while (true) {
        const result1: any = await reader1?.read();
        console.log(result1);
        if (result1) {
          const { done, value } = result1;
          if (done) break;
          codeChunks += new TextDecoder().decode(value);
          console.log("codeChunks", codeChunks);
          setResponse((prevState: any) => {
            const updatedState = [...prevState];
            updatedState[index] = {
              ...updatedState[index],
              testCases: codeChunks,
            };
            return updatedState;
          });
        }
      }
    } catch {
    } finally {
      setPromptValue("");
    }
  };

  const generateUserStory = async () => {
    if (activePageId) {
      try {
        const payload = {
          project_page_id: activePageId,
        };
        const response: any = await mockupGeneratorService.getUserStoryDetails(
          payload
        );
        console.log("response", response.data);
        const tempUserStoryArray: any = [];
        response.data.user_stories.map((e: any) => {
          tempUserStoryArray.push({
            userStory: {
              user_story: e.user_story,
              user_story_id: e.user_story_id,
            },
            acceptanceCriteria: e.acceptance_criteria,
            testCases: e.test_cases,
          });
        });
        setResponse(tempUserStoryArray);
        return response.data.msg_id;
      } catch (error) {
        console.error("Error fetching user story details:", error);
        return null;
      }
    }
    return null;
  };
  const deleteUserStory = async (id: number) => {
    try {
      const payload = {
        user_story_id: id,
      };
      const Deleteresponse = await mockupGeneratorService.deleteUserStory(
        payload
      );
      if (Deleteresponse) {
        const tempResponseArray = response.filter(
          (e: any) => e.userStory.user_story_id !== id
        );
        setResponse(tempResponseArray);
      }
    } catch {}
  };
  const handleAddUserStory = async () => {
    setNewUserStoryVisable(false);
    try {
      const payload = {
        project_page_id: activePageId,
        user_story: newUserStoryValue,
        user_story_id: 0,
      };
      const upadateResponse: any = await mockupGeneratorService.updateUserStory(
        payload
      );
      console.log(upadateResponse.data.msg_id);
      if (upadateResponse.data.msg_id) {
        let tempUserStoryObj = {
          userStory: {
            user_story: newUserStoryValue,
            user_story_id: upadateResponse.data.user_story_id,
          },
          acceptanceCriteria: "",
          testCases: "",
        };
        console.log(tempUserStoryObj);

        setResponse([tempUserStoryObj, ...response]);
        handleAcceptanceCriteria(
          newUserStoryValue,
          upadateResponse.data.user_story_id,
          0
        );
        setNewUserStoryValue("");
      }
    } catch {}
  };
  const handleSaveTestCase = async (id: number) => {
    console.log(testCaseChangeValue);
    try {
      const payload = {
        project_page_id: activePageId,
        test_cases: testCaseChangeValue,
        user_story_id: id,
      };
      const updateResponse: any = await mockupGeneratorService.updateUserStory(
        payload
      );
      console.log(updateResponse.data.msg_id);
      if (updateResponse.data.msg_id) {
        const updatedResponse = response.map((e: any) =>
          e.userStory.user_story_id === id
            ? { ...e, testCases: testCaseChangeValue }
            : e
        );
        setResponse(updatedResponse);
        setIsTestCaseEditable(0);
        setTestCaseChangeValue("")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveAcceptanceCriteria = async (id: number) => {
    console.log(testCaseChangeValue);
    try {
      const payload = {
        project_page_id: activePageId,
        acceptance_criteria: acceptanceCriteriaChangeValue,
        user_story_id: id,
      };
      const updateResponse: any = await mockupGeneratorService.updateUserStory(
        payload
      );
      console.log(updateResponse.data.msg_id);
      if (updateResponse.data.msg_id) {
        const updatedResponse = response.map((e: any) =>
          e.userStory.user_story_id === id
            ? { ...e, acceptanceCriteria: acceptanceCriteriaChangeValue }
            : e
        );
        setResponse(updatedResponse);
        setIsAcceptanceCriteriaEditable(0);
        setAcceptanceCriteriaChangeValue("")
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSaveUserStory = async (id:number) => {
    console.log(userStoryChangeValue);
    try {
      const payload = {
        project_page_id: activePageId,
        user_story: userStoryChangeValue,
        user_story_id: id,
      };
      const updateResponse = await mockupGeneratorService.updateUserStory(payload);
      console.log(updateResponse.data.msg_id);
      if (updateResponse.data.msg_id) {
        const updatedResponse = response.map((e:any) =>
          e.userStory.user_story_id === id
            ? { ...e, userStory: { ...e.userStory, user_story: userStoryChangeValue } }
            : e
        );
        setResponse(updatedResponse);
        setIsUserStoryEditable(0);
        setUserStoryChangeValue("")
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const msgId = await generateUserStory();
      if (msgId !== 1) {
        handleUserStory();
      }
    };
    fetchData();
  }, [activePageId]);
  //   useEffect(()=>{
  //       handleAcceptanceCriteria()
  //   },[userStoryResponseStatus])

  const toggleCollapse = (index: number) => {
    setCollapseControllers((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };
  console.log(
    "userStoryResponse",
    response[0]?.acceptanceCriteria,
    userStoryResponseStatus,
    projectPageLists
  );
  console.log(response);

  return (
    <>
      <div className="htmlMockupButtonWrap">
        <SyncOutlined onClick={handleUserStory} spin={requirementApiStatus} />
        <Popover
          open={newUserStoryVisable}
          trigger="click"
          placement="left"
          content={
            <Input
              placeholder="Write A new User Story..."
              size="large"
              defaultValue={newUserStoryValue}
              suffix={
                <PlusCircleTwoTone
                  onClick={newUserStoryValue ? handleAddUserStory : undefined}
                />
              }
              onChange={(e) => setNewUserStoryValue(e.target.value)}
            />
          }
        >
          <PlusCircleFilled
            className="addUserStoryIcon"
            onClick={() =>
              activePageId
                ? setNewUserStoryVisable(!newUserStoryVisable)
                : undefined
            }
          />
        </Popover>
      </div>
      {response?.map((e: any, index: number) => (
        <div className="userStoryTitle" key={index}>
          <div className="userStoryMainContainer">
            {e?.userStory?.user_story ? (
              isUserStoryEditable===e?.userStory?.user_story_id ? (
                <TextArea
                  defaultValue={e?.userStory?.user_story}
                  onChange={(e) =>
                    setUserStoryChangeValue(e.target.value)
                  }
                  rows={2}
                />
              ) :
              (<div>{e?.userStory?.user_story}</div>)
            ) : (
              <Skeleton />
            )}
            <div className="userStoryTitleIconWrap">
            {isUserStoryEditable===e?.userStory?.user_story_id ? (
                    <div className="testCaseIconWrap">
                      <CheckCircleOutlined
                        className="checkCircleIcon"
                        onClick={() =>userStoryChangeValue?
                          handleSaveUserStory(
                            e?.userStory?.user_story_id
                          ):null
                        }
                      />
                      <CloseCircleOutlined
                        className="closeCircleIcon"
                        onClick={() => setIsUserStoryEditable(0)}
                      />
                    </div>
                  ) : (
                    <div className="">
                      {" "}
                      <EditOutlined
                        onClick={() => setIsUserStoryEditable(e?.userStory?.user_story_id)}
                      />
                    </div>
                  )}
             
              <Popconfirm
                title="Delete this user story"
                description={
                  <span>
                    Are you sure to delete,{" "}
                    <strong>{e?.userStory?.user_story}</strong> user story?
                  </span>
                }
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteUserStory(e?.userStory?.user_story_id)}
              >
                <DeleteOutlined
                  className="
deleteIconHover"
                />
              </Popconfirm>
              <CaretDownOutlined
                className="collapseArrow"
                onClick={() => toggleCollapse(index)}
              />
            </div>
          </div>
          {!collapseControllers[index] && (
            <>
              {/* <div className="description">
          {e?.userStory.user_story}
      </div> */}
              <div className="criteriaHeader">
                Acceptance Criteria
                <Popover
                  placement="left"
                  open={
                    acceptanceCriteriaVisible.acceptanceCriteriaVisible &&
                    acceptanceCriteriaVisible.userStoryId ===
                      e?.userStory?.user_story_id
                  }
                  content={
                    <Input
                      size="large"
                      style={{ width: "100%" }}
                      onChange={(e) => {
                        setPromptValue(e.target.value);
                      }}
                      suffix={
                        <UpCircleTwoTone
                          className={
                            promptValue
                              ? "sendPromptIcon"
                              : "sendPromptIconDisabled"
                          }
                          onClick={() =>
                            promptValue
                              ? handleAcceptanceCriteria(
                                  e?.userStory?.user_story,
                                  e?.userStory?.user_story_id,
                                  index
                                )
                              : undefined
                          }
                        />
                      }
                    />
                  }
                  trigger="click"
                >
                  <SyncOutlined
                    className="criteriaIcon"
                    onClick={() =>
                      setAcceptanceCriteriaVisible({
                        userStoryId: e?.userStory?.user_story_id,
                        acceptanceCriteriaVisible:
                          !acceptanceCriteriaVisible.acceptanceCriteriaVisible,
                      })
                    }
                  />
                </Popover>
              </div>
              <div className="criteriaContainer">
                <div className="criteria" key={index}>
                  {isAcceptanceCriteriaEditable === e?.userStory?.user_story_id? (
                    <div className="testCaseIconWrap">
                      <CheckCircleOutlined
                        className="checkCircleIcon"
                        onClick={() =>acceptanceCriteriaChangeValue?
                          handleSaveAcceptanceCriteria(
                            e?.userStory?.user_story_id
                          ):null
                        }
                      />
                      <CloseCircleOutlined
                        className="closeCircleIcon"
                        onClick={() => setIsAcceptanceCriteriaEditable(0)}
                      />
                    </div>
                  ) : (
                    <div className="editIcon">
                      {" "}
                      <EditOutlined
                        onClick={() => setIsAcceptanceCriteriaEditable(e?.userStory?.user_story_id)}
                      />
                    </div>
                  )}

                  {isAcceptanceCriteriaEditable === e?.userStory?.user_story_id ? (
                    <TextArea
                      defaultValue={e?.acceptanceCriteria}
                      onChange={(e) =>
                        setAcceptanceCriteriaChangeValue(e.target.value)
                      }
                      rows={8}
                    />
                  ) : (
                    <div className="criteriaContent">
                      {e?.acceptanceCriteria ? (
                        <ReactMarkdown>{e?.acceptanceCriteria}</ReactMarkdown>
                      ) : (
                        <Skeleton />
                      )}
                    </div>
                  )}
                </div>
                <div className="criteriaHeader">
                  Test Case
                  <Popover
                    placement="left"
                    open={
                      testCaseVisible.testCaseVisible &&
                      testCaseVisible.userStoryId ===
                        e?.userStory?.user_story_id
                    }
                    content={
                      <Input
                        size="large"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setPromptValue(e.target.value);
                        }}
                        suffix={
                          <UpCircleTwoTone
                            className={
                              promptValue
                                ? "sendPromptIcon"
                                : "sendPromptIconDisabled"
                            }
                            onClick={() =>
                              promptValue
                                ? handleTestCase(
                                    e?.acceptanceCriteria,
                                    e?.userStory?.user_story_id,
                                    index
                                  )
                                : undefined
                            }
                          />
                        }
                      />
                    }
                    trigger="click"
                  >
                    <SyncOutlined
                      className="criteriaIcon"
                      onClick={() =>
                        setTestCaseVisible({
                          userStoryId: e?.userStory?.user_story_id,
                          testCaseVisible: !testCaseVisible.testCaseVisible,
                        })
                      }
                    />
                  </Popover>
                </div>
                <div className="testCase">
                  {isTestCaseEditable===e?.userStory?.user_story_id ? (
                    <div className="testCaseIconWrap">
                      <CheckCircleOutlined
                        className="checkCircleIcon"
                        onClick={() =>
                          testCaseChangeValue?handleSaveTestCase(e?.userStory?.user_story_id):null
                        }
                      />
                      <CloseCircleOutlined
                        className="closeCircleIcon"
                        onClick={() => setIsTestCaseEditable(0)}
                      />
                    </div>
                  ) : (
                    <div className="editIcon">
                      {" "}
                      <EditOutlined
                        onClick={() => setIsTestCaseEditable(e?.userStory?.user_story_id)}
                      />
                    </div>
                  )}
                  {isTestCaseEditable===e?.userStory?.user_story_id ? (
                    <TextArea
                      defaultValue={e?.testCases}
                      onChange={(e) => setTestCaseChangeValue(e.target.value)}
                      rows={8}
                    />
                  ) : (
                    <div className="criteriaContent">
                      {e?.testCases ? (
                        <ReactMarkdown>{e?.testCases}</ReactMarkdown>
                      ) : (
                        <Skeleton />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default UserStory;
