// Layout.jsx
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";

const Layout = () => {
    return (
        <>
            <Navbar />
            <main className="layoutMainContent">
                <div className="contentWrap">
                    <Outlet />
                </div>
            </main>
        </>
    );
};

export default Layout;
