import React, { useEffect } from 'react'
import { MockupStore } from '../store/Mockup.store';
import mockupGeneratorService from '../../services/mockupGenerator.service';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const BackendCode = () => {
    const {
        projectPageLists,
        setProjectPageLists,
        activePageId,
        setRequirementApiStatus,
        requirementApiStatus,
        modalState,
        setModalState
    } = MockupStore((state: any) => state);
    const { projectId, threadId,framework } = useParams();


    const generatePagewiseBackendCode = async () => {



        setRequirementApiStatus(true);
        let codeChunks = "";
        const payload = {
            page_id: activePageId,
            project_id: projectId,
            backend_framework: framework==="0"?"Python with Flask":framework==="1"?"Nest Js":"Python with FastAPI",
            thread_id: threadId,
        };
        try {
            const response: any = await mockupGeneratorService.generateBeCode(payload);
            console.log("html", response.body);
            const reader1: ReadableStreamDefaultReader<Uint8Array> | undefined =
                response.body.getReader();
            while (true) {
                const result1: any = await reader1?.read();
                console.log(result1);
                if (result1) {
                    const { done, value } = result1;
                    if (done) {
                        break;
                    }
                    codeChunks += new TextDecoder().decode(value);
                    const stableCodeChunks = codeChunks;
                    console.log(projectPageLists)
                    const updatedPages = projectPageLists.saved_page_list.map((page: any) => {
                        if (page.page_id === activePageId) {
                            return {
                                ...page,
                                backendCode: stableCodeChunks,


                            };
                        }
                        return page;
                    });

                    setProjectPageLists({
                        ...projectPageLists,
                        saved_page_list: updatedPages,
                    });
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setRequirementApiStatus(false);
        }
    };




    useEffect(() => {

        activePageId &&
            (projectPageLists?.saved_page_list?.find(
                (e: any) => e.page_id === activePageId
            )?.backEnd_code === null ||
                projectPageLists?.saved_page_list?.find(
                    (e: any) => e.page_id === activePageId
                )?.backEnd_code === undefined) &&
            generatePagewiseBackendCode();
    }, [activePageId]);
    console.log(projectPageLists.saved_page_list)
    return (
        <>
            {projectPageLists?.saved_page_list?.map((el: any) =>
                el.page_id === activePageId ? (
                    <div className="requirementContainer">
                        <div className="htmlMockupButtonWrap">
                        </div>
                        <div
                            className="content-textarea"
                        >
                            <p>
                                <ReactMarkdown>{el.backendCode}</ReactMarkdown>
                            </p>
                        </div>
                    </div>
                ) : null
            )}
        </>
    )
}

export default BackendCode