import React, { useEffect, useRef, useState } from "react";
import { MockupStore } from "../store/Mockup.store";
import ReactMarkdown from "react-markdown";
import mockupGeneratorService from "../../services/mockupGenerator.service";
import { useNavigate, useParams } from "react-router-dom";
import { EditOutlined, SyncOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import MDEditor from "@uiw/react-md-editor";
import TipTapEditor from "../TiptapEditors/TipTapEditor";
import { Button } from "antd";

const MainContent: React.FC = () => {
  const {
    projectPageLists,
    setProjectPageLists,
    activePageId,
    setRequirementApiStatus,
    requirementApiStatus,
  } = MockupStore((state: any) => state);
  const { projectId, threadId } = useParams();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [editorvalue, setEditorvalue] = useState<string>("");
  const requirementTextRef = useRef<HTMLDivElement>(null);
 

  const handleUpdateReq = async () => {
   
    console.log(editorvalue);
    try {
      const payload = {
        project_id: projectId,
        project_page_id: activePageId,
        req_doc: editorvalue,
      };
      const response = await mockupGeneratorService.updateProjectPageDetails(
        payload
      );
      if (response) {
        const updatedPages = projectPageLists.saved_page_list.map(
          (page: any) => {
            if (page.page_id === activePageId) {
              return {
                ...page,
                req_doc: editorvalue,
              };
            }
            return page;
          }
        );
        setProjectPageLists({
          ...projectPageLists,
          saved_page_list: updatedPages,
        });
        setIsEditable(false);
        setEditorvalue("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReqirement = async () => {
    setRequirementApiStatus(true);
    try {
      const generateRdpayload = {
        page_id: activePageId,
        page_title:
          projectPageLists.saved_page_list.find(
            (e: any) => e.page_id === activePageId
          )?.page_title || null,
        thread_id: threadId,
      };
      const response: any = await mockupGeneratorService.generateRd(
        generateRdpayload
      );
      const reader: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      let chunks: any = "";
      while (true) {
        const result: any = await reader?.read();
        if (result) {
          const { done, value } = result;
          if (done) {
            break;
          }
          chunks += new TextDecoder().decode(value);

          const updatedPages = projectPageLists.saved_page_list.map(
            (page: any) => {
              if (page.page_id === activePageId) {
                return {
                  ...page,
                  req_doc: chunks, // Update req_doc for the matching page_id
                };
              }
              return page; // Return the unchanged page object for other page_ids
            }
          );

          setProjectPageLists({
            ...projectPageLists,
            saved_page_list: updatedPages,
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequirementApiStatus(false);
    }
  };

  console.log(activePageId);
  useEffect(() => {
    setEditorvalue("");
    setIsEditable(false)
    activePageId &&
      (projectPageLists?.saved_page_list?.find(
        (e: any) => e.page_id === activePageId
      )?.req_doc === null ||
        projectPageLists?.saved_page_list?.find(
          (e: any) => e.page_id === activePageId
        )?.req_doc === undefined) &&
      handleReqirement();
  }, [activePageId]);

  return (
    <>
      {projectPageLists?.saved_page_list?.map((el: any) =>
        el.page_id === activePageId ? (
          <div className="requirementContainer">
            <div className="htmlMockupButtonWrap">
            {!isEditable &&  <SyncOutlined
                onClick={activePageId ? handleReqirement : undefined}
                spin={requirementApiStatus}
              />}
               
             {isEditable? <div className="saveCancelButtonWrap"> <Button
                className="save-button"
                onClick={handleUpdateReq}
                disabled={editorvalue.length===0}
              >
                Save
              </Button>
              <Button className="cancelbutton"  onClick={() => setIsEditable(false)}>Cancel</Button>
              </div>:<EditOutlined onClick={() => setIsEditable(true)} disabled={requirementApiStatus}/>}
             
            </div>
            <div
              className="content-textarea"
              
            >
              {!isEditable ? (
                <p
                  ref={requirementTextRef}
                  
                >
                 
                  <ReactMarkdown>{el.req_doc}</ReactMarkdown>
                </p>
              ) : (
                <TipTapEditor
                  requirementRef={requirementTextRef.current?.innerHTML}
                  setEditableMarkdownText={setEditorvalue}
                />
              )}
            </div>
          </div>
        ) : null
      )}
    </>
  );
};

export default MainContent;
