/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import MenuBar from './MenuBar';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import BulletList from '@tiptap/extension-bullet-list';
import { MockupStore } from '../store/Mockup.store';
import { Markdown } from 'tiptap-markdown';
import FontSize from './FontSize';
import TextStyle from '@tiptap/extension-text-style';
import Heading from '@tiptap/extension-heading'

interface TipTapEditorProps {
  requirementRef: any;
  setEditableMarkdownText: any;
}

const TipTapEditor: React.FC<TipTapEditorProps> = ({ requirementRef, setEditableMarkdownText }) => {
  const { projectPageLists, setProjectPageLists, activePageId, setRequirementApiStatus, requirementApiStatus } = MockupStore((state: any) => state);

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const editor = useEditor({
    editable: true,
    extensions: [
      
      Heading.configure({
        levels: [1, 2, 3],
      }),
      TextStyle,
      Markdown,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      Underline,
      BulletList,
    ],
    autofocus: true,
    content: requirementRef,
    onUpdate: ({ editor }) => {
      setEditableMarkdownText(editor.storage.markdown.getMarkdown());
      console.log('Updated Markdown:', editor.storage.markdown.getMarkdown());
    },
  });

  return (
    <div className="textEditor customTiptapEditor">
      <MenuBar editor={editor} />
      <div className="editorFldWrap" id="parent-div">
        <div className="editorContentWrap">
          <EditorContent editor={editor} />
        </div>
      </div>
    </div>
  );
};

export default TipTapEditor;
