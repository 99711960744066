import React from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SearchBox = () => {
  return (
    <div className="search-box">
      <Input
        placeholder="Search"
        prefix={<SearchOutlined />}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default SearchBox;
