import React from 'react';
import { SoundOutlined } from '@ant-design/icons';

interface VoiceCardProps {
  voiceName: string;
  isSelected: boolean;
  onSelect: (voiceName: string) => void;
}

const VoiceCard: React.FC<VoiceCardProps> = ({ voiceName, isSelected, onSelect }) => {
  const handleClick = () => {
    onSelect(voiceName);
  };

  return (
    <div
      className="voiceCard"
      onClick={handleClick}
      style={{
        cursor: 'pointer',
        border: isSelected ? '2px solid blue' : '1px solid #ccc', // Blue border for selected
        borderRadius: '5px',
        padding: '10px'
      }}
    >
      <h6 className="voiceName">{voiceName?.replace("en-US-", "")}</h6>
      <SoundOutlined className="voiceIcon" />
    </div>
  );
};

export default VoiceCard;
