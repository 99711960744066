import React, { useState } from 'react';
import { useStore } from 'zustand';
import { NewsAnchorStore } from '../components/store/NewsAnchor.store';

const colorOptions = [
  '#ffffff', '#f0f0f0', '#e0e0e0', '#d0d0d0', '#c0c0c0', '#b0b0b0', '#a0a0a0', '#909090', '#808080', '#707070',
  '#606060', '#505050', '#404040', '#303030', '#202020', '#101010', '#000000', '#ff0000', '#ff4040', '#ff8080',
  '#ffb3b3', '#ffcccc', '#ff9999', '#ff6666', '#ff3333', '#cc0000', '#cc3333', '#cc6666', '#cc9999', '#cccccc',
  '#00ff00', '#33ff33', '#66ff66', '#99ff99', '#ccffcc', '#00cc00', '#33cc33', '#66cc66', '#99cc99', '#cccc00',
  '#ffff00', '#ffff33', '#ffff66', '#ffff99', '#ffffcc', '#cccc33', '#cccc66', '#cccc99', '#cccc00', '#ccff00',
  '#00ffff', '#33ffff', '#66ffff', '#99ffff', '#ccffff', '#00cccc', '#33cccc', '#66cccc', '#99cccc', '#00ccff',
  '#0000ff', '#3333ff', '#6666ff', '#9999ff', '#ccccff', '#0000cc', '#3333cc', '#6666cc', '#9999cc', '#000099',
  '#ff00ff', '#ff33ff', '#ff66ff', '#ff99ff', '#ffccff', '#cc00cc', '#cc33cc', '#cc66cc', '#cc99cc', '#cc00ff',
  '#ff6600', '#ff9933', '#ffcc66', '#ff9966', '#ffcc99', '#cc6600', '#cc9933', '#cc9966', '#cc9999', '#cc6600'
];

export const Background: React.FC = () => {
  const { payload, updatePayload } = useStore(NewsAnchorStore);
  const [selectedColor, setSelectedColor] = useState<string>(payload.bg_color);

  const handleColorClick = (color: string) => {
    setSelectedColor(color);
    updatePayload({ bg_color: color });
  };

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h3>Select a Background</h3>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {colorOptions.map((color) => (
          <div
            key={color}
            onClick={() => handleColorClick(color)}
            style={{
              width: '25px',
              height: '25px',
              margin: '3px',
              borderRadius: '5px',
              backgroundColor: color,
              cursor: 'pointer',
              border: color === selectedColor ? '2px solid blue' : '1px solid #000', // Blue border for selected
            }}
          />
        ))}
      </div>
      <div
        style={{
          marginTop: '20px',
          width: '100%',
          height: '100px',
          backgroundColor: selectedColor,
          borderRadius: '8px',
          border: '1px solid #ccc',
        }}
      />
    </div>
  );
};
