

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import mockupGeneratorService from '../../services/mockupGenerator.service';
import { useLocation, useParams } from 'react-router-dom';
import { MockupStore } from '../store/Mockup.store';

interface NavItemProps {
  projectPageTitle: string;
  onClick: () => void;
  active: boolean;
  pageId:number
}

const NavItem: React.FC<NavItemProps> = ({ projectPageTitle, onClick, active,pageId }) => {
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [pageTitleChangeValue, setPageTitleChangeValue] = useState("");
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
   
  const { projectId,threadId } = useParams();
  const {projectPageLists,setProjectPageLists,requirementApiStatus}=MockupStore((state: any) => state)
  

  useEffect(() => {
    const currentPath: any = location.pathname;
    setActiveTab(currentPath);
    console.log(currentPath);
  }, [location]);

  const handleEditProjectPage = () => {
    setIsEditEnabled(true);
  }
  const handleProjectPageUpdate = async () => {
   
    if(pageTitleChangeValue){
    try {
      const payload = {
        project_id: projectId,
        project_page_id: pageId,
        page_title: pageTitleChangeValue,
        req_doc: "",
        html_code: "",
        css_code: "",
        js_code: ""
      }
      const response = await mockupGeneratorService.updateProjectPageDetails(payload);
      if(response.data.msg_id)
      {
        setIsEditEnabled(false);
        const updatedPageLists = projectPageLists.saved_page_list?.map((e: any) => {
          if (e.page_id === pageId) {
            return { ...e, page_title: pageTitleChangeValue };
          }
          return e;
        });
        setProjectPageLists({ ...projectPageLists, saved_page_list: updatedPageLists });
        
      }
    }
    catch (error) {
      console.log(error)

    }
  }
  else
  setIsEditEnabled(false);
  }
  const handleDeleteProjectPage=async()=>
  {
    try
    {
      const payload={
        project_page_id: pageId
      }
      const response=await mockupGeneratorService.deleteProjectPage(payload)
      console.log(response)
      if(response.data.msg_id)
      {
      const updateProjectPageLists=  projectPageLists.saved_page_list?.filter((e:any)=>e.page_id!==pageId)
      setProjectPageLists({...projectPageLists, saved_page_list:updateProjectPageLists})
      }
    }
    catch(error){
      console.log(error)
    }
  }
  //console.log(activeTab.includes("htmlMockup"));
  return (
    <>
      {
        isEditEnabled ? <Input defaultValue={projectPageTitle} onBlur={handleProjectPageUpdate} autoFocus onChange={(e) => setPageTitleChangeValue(e.target.value)}></Input> :
          <Tooltip
            title={
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <EditOutlined className='tooltipTitleIcon' onClick={handleEditProjectPage} />
                <DeleteOutlined className='tooltipTitleIcon' onClick={handleDeleteProjectPage} />
              </div>
            }
            color="#9b72cb"
            placement="right"
          >
          {/* <div
  className={`${requirementApiStatus || (activeTab.includes("htmlMockup") && projectPageLists?.saved_page_list?.find((e: any) => e.page_id === pageId && e.requirementDoc === undefined)) ? "navItemDisabled" : "navItem"} ${active ? 'active' : ''}`}
  onClick={onClick}
>
  {projectPageTitle}
</div> */}

<div className={`${requirementApiStatus ||  ((activeTab.includes("htmlMockup") || activeTab.includes("userStory")) && (projectPageLists?.saved_page_list?.find((e: any) => e.page_id === pageId)?.req_doc===null || projectPageLists?.saved_page_list?.find((e: any) => e.page_id === pageId)?.req_doc === undefined) )? "navItemDisabled":"navItem"} ${active ? 'active' : ''}`} onClick={onClick}>
              {projectPageTitle}
            </div>

          </Tooltip>
      }
    </>
  );
};

export default NavItem;
