// import React from 'react';
// import './App.css';
// import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
// import Navbar from './components/Navbar';
// import Home from './components/Home';
// import ErrorPage from './components/ErrorPage';
// import DbStructureGenerator from './components/DbStructureGenerator';
// import MockupGenerator from './components/MockupGenerator';
// import './assets/css/style.css'
// import { NewMockupProject } from './components/NewMockupProject';
// import GptAssesment from './components/GptAssesment';
// import ChatAssesment from './components/ChatAssesment';

// function App() {
//   return (
//     <>
//       <Router>
//         <Navbar />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/gen-db-structure" element={<DbStructureGenerator />} />
//           <Route path="/MockupGenerator" element={<MockupGenerator/>} />
//           <Route path='/MockupGenerator/newProjectGenerate' element={<NewMockupProject/>}/>
//           <Route path='/gpt-assesment' element={<GptAssesment/>}/>
//           <Route path='/gpt-assesment/chat' element={<ChatAssesment/>}/>
//           <Route path="*" element={<ErrorPage />} />
//         </Routes>
//       </Router>
//     </>
//   );
// }

// export default App;

import React from 'react'
import { RouterProvider } from "react-router-dom";
import './App.css'
import { ConfigProvider } from "antd";
import router from "./components/login/Route"
import './assets/css/style.css'

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Roboto',
          colorPrimary: "#173753",
          colorLink: '#173753',
          colorLinkActive: '#3c3c3c',
          colorLinkHover: '#3c3c3c',
          colorError: "#c00",
          colorBorder: "#949494",
          borderRadius: 4,
          fontSize: 14,
        },
      }}
    >
      <React.Suspense fallback={<p>Loading...</p>}>
        <RouterProvider router={router} />
      </React.Suspense>
    </ConfigProvider>
  );
}

export default App

