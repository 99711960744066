import Cookies from "js-cookie";
import { CurrentUserType, TokenParsedType } from "./user.type";
import useAppNotification from "../../hooks/notificationHooks";
import { fireGetCurrentUser } from "./CommonFirebase";
import dayjs from "./useDate";

const TOKEN_COOKIE_NAME = "wiaUser";

function parseJwt(token: string) {
    if (!token) return;
    let base64Url = token.split(".")?.[1];
    if (!base64Url) return;
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

export const setTokenCookie = (
    token: string,
    otherInfo?: CurrentUserType
): void => {
    Cookies.set(TOKEN_COOKIE_NAME, `${token ?? new Date().getTime()}`);
    if (otherInfo)
        localStorage.setItem(TOKEN_COOKIE_NAME, JSON.stringify(otherInfo));
};

export const removeTokenCookie = (): any => {
    Cookies.remove(TOKEN_COOKIE_NAME);
    localStorage.setItem(TOKEN_COOKIE_NAME, JSON.stringify(""));
    //useAppNotification({ message: "You need to login!" });

    const { origin } = window.location;
    window.location.replace(origin + "/login");
};

export const getTokenCookie = (): TokenParsedType => {
    const rawToken = Cookies.get(TOKEN_COOKIE_NAME);
    if (!rawToken) return removeTokenCookie();
    const token: any = parseJwt(rawToken) || {};
    const expTime = dayjs(token.exp * 1000);
    const isExpired = expTime < dayjs();
    const isAlmostExpired = expTime < dayjs().add(30, "m");

    return {
        ...token,
        rawToken,
        commonInfo:
            JSON.parse(localStorage.getItem(TOKEN_COOKIE_NAME) ?? "{}") || {},
        isExpired,
        isAlmostExpired,
    };
};

export const refreshToken = async () => {
    const user = getTokenCookie();
    if (user.isAlmostExpired) {
        const token = await fireGetCurrentUser();
        if (token) {
            setTokenCookie(token);
        } else {
            const { origin } = window.location;
            window.location.replace(origin + "/login");
        }
    }
};
