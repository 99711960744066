import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Card, Carousel, Radio, RadioChangeEvent } from 'antd';
import Meta from 'antd/es/card/Meta';
import React from 'react';
import template1 from "../../assets/images/template1.png"
import template2 from "../../assets/images/template2.png"
import template3 from "../../assets/images/template3.png"
import template4 from "../../assets/images/template4.png"
import template6 from "../../assets/images/template6.png"


interface TemplateGalleryProps {
  selectedTemplate: string | null;
  setSelectedTemplate: (value: string | null) => void;
  setTemplateToggaleView: (value: boolean) => void;
  iscarouselView:boolean
}

const templates = [
  {
    title: 'Project proposal 1',
    img: template1,
    id:"template-1",
  },
  {
    title: 'Project proposal 2',
    img: template2,
    id: "template-2",
  },
  {
    title: 'Project proposal 3',
    img: template3,
    id:"template-3",
  },
  {
    title: 'Project proposal 4',
    img: template4,
    id: "template-4",
  },
  {
    title: 'Project proposal 5',
    img: template6,
    id: "template-6",
  },
];

const TemplateGallery: React.FC<TemplateGalleryProps> = ({
  selectedTemplate,
  setSelectedTemplate,
  setTemplateToggaleView,
  iscarouselView
}) => {
  const handleTemplateChange = (e: RadioChangeEvent) => {
    setSelectedTemplate(e.target.value);
    console.log(e.target.value)
  };

  const handleApply = async () => {
    setTemplateToggaleView(false);
    if (selectedTemplate) {
      console.log(`Applying template: ${selectedTemplate}`);
    } else {
      console.log('No template selected');
    }
  };

  return (
    <>
      <div className="templateGallery">
        <h4>Template gallery</h4>
       {iscarouselView? <Carousel
          arrows
          slidesToShow={1}
          slidesToScroll={1}
          infinite={false}
          prevArrow={<ArrowLeftOutlined />}
      nextArrow={<ArrowRightOutlined />}
      className="custom-carousel"
        >
          {templates.map((template) => (
            <div className='templateGalleryCard' key={template.id}>
              <Radio.Group
                onChange={handleTemplateChange}
                value={selectedTemplate}
              >
                <Radio value={template.id} key={template.id}>
                  <Card
                    hoverable
                    cover={<img alt={template.title} src={template.img} />}
                    className="templateCard"
                  >
                    <Meta title={template.title} />
                  </Card>
                </Radio>
              </Radio.Group>
            </div>
          ))}
        </Carousel>: <>{templates.map((template) => (
            <div className='templateGalleryCard' key={template.id}>
              <Radio.Group
                onChange={handleTemplateChange}
                value={selectedTemplate}
              >
                <Radio value={template.id} key={template.id}>
                  <Card
                    hoverable
                    cover={<img alt={template.title} src={template.img} />}
                    className="templateCard"
                  >
                    <Meta title={template.title} />
                  </Card>
                </Radio>
              </Radio.Group>
            </div>
          ))}</>}
        </div>
      
      <div className="drawerFooter">
        <ArrowRightOutlined
          className={
            selectedTemplate ? 'applyButton' : 'applyButtonDisabled'
          }
          onClick={selectedTemplate ? handleApply : undefined}
        />
      </div>
    </>
  );
};

export default TemplateGallery;
