import { createBrowserRouter, Outlet, redirect } from "react-router-dom";
import { getTokenCookie } from "./useAuthenticate";
import UserLogin from "./UserLogin";
import ProtectedRoute from "./ProtectedRoute";
import GptAssesment from "../GptAssesment";
import ChatAssesment from "../ChatAssesment";
import Layout from "./Layout";
import MockupGenerator from "../mockupCode/MockupGenerator";
import { NewMockupProject } from "../mockupCode/NewMockupProject";
import MainContent from "../mockupCode/Requirement";
import MainWrapper from "../mockupCode/MainWrapper";
import UserStory from "../mockupCode/UserStory";
import HtmlMockup from "../mockupCode/HtmlMockup";
import NewsAnchorMain from "../../newsAnchor/NewsAnchorMain";
import BackendCode from "../mockupCode/BackendCode";
import Frontend from "../mockupCode/Frontend";


const router = createBrowserRouter([
    {
        path: "/",
        index: true,
        element: <></>,
        loader: async () => {
            const user = getTokenCookie();
            return !user?.rawToken ? redirect("/login") : redirect("/MockupGenerator");
        },
    },
    {
        path: "/AiNewsAnchor",
                element: <NewsAnchorMain/>,
    },
    {
        path: "/login",
        element: <UserLogin/>,
    },
    {
        path: "/",
        element: (
            <ProtectedRoute>
              <Layout/>
            </ProtectedRoute>
        ),
        children: [
            // {
            //     path: "/gpt-assessment",
            //     element: <GptAssesment />,
            // },
            // {
            //     path: "/gpt-assessment/chat/:threadId/:topic",
            //     element: <ChatAssesment />,
            // },
            {
                path: "/MockupGenerator",
                element: <MockupGenerator/>,
            },
            {
                path: "/MockupGenerator/requirement/:projectId/:threadId",
                element: <MainWrapper><MainContent/></MainWrapper>,
            },
            {
                path: "/MockupGenerator/userStory/:projectId/:threadId",
                element:<MainWrapper><UserStory/></MainWrapper>,
            },
            {
                path: "MockupGenerator/htmlMockup/:projectId/:threadId",
                element: <MainWrapper><HtmlMockup/></MainWrapper>,
            },
            {
                path: "MockupGenerator/backend/:projectId/:threadId/:framework",
                element: <MainWrapper><BackendCode/></MainWrapper>,
            },
            {
                path: "MockupGenerator/frontend/:projectId/:threadId/:framework",
                element: <MainWrapper><Frontend/></MainWrapper>,
            }
          
        ],
    },
]);

export default router;
