import React, { useState } from "react";
import {  Drawer } from "antd";
import type { DrawerProps } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TemplateAdditionalForm from "./TemplateAdditionalForm";
import TemplateGallery from "./TemplateGallery";

interface IRightDrawerProps {
  isOpen: boolean;
  placement: DrawerProps["placement"];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RightDrawer: React.FC<IRightDrawerProps> = ({
  isOpen,
  placement,
  setOpen,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [templateToggaleView, setTemplateToggaleView] = useState(true);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      title={
        <h5 className="drawerTitle">
          {!templateToggaleView && (
            <ArrowLeftOutlined
              className="drawerArrowicon"
              onClick={() => setTemplateToggaleView(true)}
            />
          )}
          <span>Start a new project</span>
        </h5>
      }
      placement={placement}
      closable={false}
      onClose={onClose}
      open={isOpen}
      key={placement}
    >
      {templateToggaleView ? (
        
        <TemplateGallery
          setTemplateToggaleView={setTemplateToggaleView}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate} 
          iscarouselView={false}
        />
    
      ) : (
        <div>
          <TemplateAdditionalForm selectedTemplateId={selectedTemplate} />
        </div>
      )}
    </Drawer>
  );
};

export default RightDrawer;
