import React, { useState } from 'react';
import { Collapse } from 'antd';
import VoiceCard from './VoiceCard';
import VoiceStyleCards from './VoiceStyle';
import { useStore } from 'zustand';
import { NewsAnchorStore } from '../components/store/NewsAnchor.store';

const { Panel } = Collapse;

const maleVoices = ['Voice 1', 'Voice 2', 'Voice 3', 'Voice 4'];
const femaleVoices = ['Voice 5', 'Voice 6', 'Voice 7', 'Voice 8'];

const VoiceAccordion = () => {
    const { payload, updatePayload,masterPresenterResponseArray } = useStore(NewsAnchorStore);
  const [selectedVoice, setSelectedVoice] = useState<string>(payload.voice_id);

  const handleVoiceSelect = (voiceName: string) => {
    setSelectedVoice(voiceName);
    updatePayload({ voice_id: voiceName });
  };
console.log(masterPresenterResponseArray?.voice_id_list.male)
  return (
    <Collapse defaultActiveKey={['1', '2', '3']} bordered={false}>
      <Panel header="Male Voice" key="1" className="customPanel">
        <div className="voiceGrid">
          {masterPresenterResponseArray?.voice_id_list?.male.map((voice:any) => (
            <VoiceCard
              key={voice}
              voiceName={voice}
              isSelected={payload.voice_id === voice}
              onSelect={handleVoiceSelect}
            />
          ))}
        </div>
      </Panel>
      <Panel header="Female Voice" key="2" className="customPanel">
        <div className="voiceGrid">
          {masterPresenterResponseArray?.voice_id_list?.female.map((voice:any) => (
            <VoiceCard
              key={voice}
              voiceName={voice}
              isSelected={payload.voice_id === voice}
              onSelect={handleVoiceSelect}
            />
          ))}
        </div>
      </Panel>
      <Panel header="Voice Style" key="3" className="customPanel">
        <div className="voiceGrid">
          <VoiceStyleCards />
        </div>
      </Panel>
    </Collapse>
  );
};

export default VoiceAccordion;
