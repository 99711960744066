import { LogoutOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "../assets/css/style.css"
import { removeTokenCookie } from './login/useAuthenticate';
const navlogo = require('../assets/images/logo.png');

function Navbar() {
    const navigation = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("");

    useEffect(() => {
        const currentPath: any = location.pathname;
        setActiveTab(currentPath);
        console.log(currentPath);
    }, [location]);
    const handleLogout=()=>
    {
        removeTokenCookie()
    }

    return (
        <>
            {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <img src={navlogo} alt="logo" width="30" height="24" className="d-inline-block align-text-top logo" />
                <div className="container-fluid justify-content-center">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-nav">
                        {/* <b className={`nav-link ${activeTab === "/" ? "active" : ""}`} aria-current="page" onClick={() => navigation("/")}>User Story Generator</b>
                        <b className={`nav-link ${activeTab.includes("gen-db-structure") ? "active" : ""}`} onClick={() => navigation("/gen-db-structure")}>Database Generator</b>
                        <b className={`nav-link ${activeTab.includes("MockupGenerator") ? "active" : ""}`} onClick={() => navigation("/MockupGenerator")}>HTML CSS Generator</b> 
                        <b className={`nav-link ${activeTab.includes("gpt-assessment") ? "active" : ""}`} onClick={() => navigation("/gpt-assessment")}>GPT Assessment</b>
                    </div>
                </div>
                <LogoutOutlined onClick={handleLogout}/>
            </nav> */}
            <div className="top-nav">
      <b  onClick={() => navigation("/MockupGenerator")}>Project Blueprint</b>
      {/* <b  onClick={() => navigation("/gpt-assessment")}>GPT Assessment</b> */}
{     localStorage.getItem("wiaUser") && <div className="user-section" onClick={handleLogout}>{(JSON.parse(localStorage.getItem("wiaUser")!)).email.split('.')[0].charAt(0).toUpperCase() + JSON.parse(localStorage.getItem("wiaUser")!).email.split('.')[0].slice(1)} ▼</div>
}      
    </div>
        </>
    )
}

export default Navbar;