import React, { useState, useEffect } from "react";
import axios from "axios";
import { XMLParser } from "fast-xml-parser";
import { Card } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";


interface NewsFeedProps {
  onPlayClick: (title: string,index:number) => void;
  activeIndex:number
}

const NewsFeed: React.FC<NewsFeedProps> = ({ onPlayClick,activeIndex }) => {
  const [newsItems, setNewsItems] = useState<any[]>([]);

  useEffect(() => {
    const fetchRSS = async () => {
      try {
        const response = await axios.get(
          "https://rss.nytimes.com/services/xml/rss/nyt/World.xml",
          {
            headers: {
              "Content-Type": "application/rss+xml",
              "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3",
            },
          }
        );

        const parser = new XMLParser();
        const jsonData = parser.parse(response.data);
        const items = jsonData.rss.channel.item;
        setNewsItems(items);
        
      } catch (error) {
        console.error("Error fetching RSS feed:", error);
      }
    };

    fetchRSS();
  }, []);

  return (
    <div className="newsFeedContainer">
      <h1>Latest News</h1>
      {newsItems.map((item, index) => (
        <Card key={index} className="newsCard">
          <div className="newsContent">
            <h3 className="newsTitle">{item.title}</h3>
            <p className="newsDescription">{item.description}</p>
          </div>
          <PlayCircleFilled 
           spin={activeIndex===index}
            className="playIcon" 
            onClick={() => activeIndex===index?undefined:onPlayClick(`${item.title} ${item.description}`,index)} 
            disabled={activeIndex===index}
          />
        </Card>
      ))}
    </div>
  );
};

export default NewsFeed;
