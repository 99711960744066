import { memo, useLayoutEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-xcode";

type AutoCompleteElementType = {
    name: string;
    description: string;
    meta: string;
};

interface EditorProps {
    onChangeProp: (value: string, index: number) => void;
    data: string | undefined;
    customAutoComplete?: AutoCompleteElementType[];
    index: number
}

const HtmlEditor = (props: EditorProps): JSX.Element => {
    const { data, onChangeProp, index } = props;

    const handleCustomAutocomplete = () => {
        // @ts-ignore
        const langTools = ace.acequire("ace/ext/language_tools");
        var sqlTables = props?.customAutoComplete ?? [];
        var sqlTablesCompleter = {
            // @ts-ignore
            getCompletions: function (editor, session, pos, prefix, callback) {
                callback(
                    null,
                    sqlTables.map(function (table) {
                        return {
                            caption: table.description,
                            value: table.name,
                            meta: table.meta,
                        };
                    })
                );
            },
        };
        langTools.setCompleters([
            langTools.snippetCompleter,
            langTools.keyWordCompleter,
        ]);
        langTools.addCompleter(sqlTablesCompleter);
    };

    useLayoutEffect(() => {
        handleCustomAutocomplete();
    }, [props.customAutoComplete]);

    return (
        <AceEditor
            aria-label="editor"
            mode="html"
            theme="xcode"
            name="editor"
            fontSize={16}
            minLines={40}
            maxLines={40}
            width="100%"
            showPrintMargin={false}
            showGutter
            // placeholder="Write your Query here..."
            editorProps={{ $blockScrolling: true }}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
            }}
            value={data}
            onChange={(value: any) => onChangeProp(value, index)}
        />
    );
};

export default memo(HtmlEditor);
