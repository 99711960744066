import React from 'react';
import { FaBold, FaHeading, FaItalic, FaListUl, FaRedo, FaStrikethrough, FaUnderline, FaUndo } from 'react-icons/fa';
import { Editor } from '@tiptap/react';

interface MenuBarProp {
  editor: Editor | null;
}

const MenuBar: React.FC<MenuBarProp> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  const handleSetFontSize = (size: string) => {
    editor.chain().focus().setFontSize(size).run();
  };

  
  return (
    <div className="editorOptions">
      <div className="formatOptions">
        <button onClick={() => editor.chain().focus().toggleBold().run()} className={editor.isActive('bold') ? 'is_active' : ''}>
          <FaBold />
        </button>
        <button onClick={() => editor.chain().focus().toggleItalic().run()} className={editor.isActive('italic') ? 'is_active' : ''}>
          <FaItalic />
        </button>
        <button onClick={() => editor.chain().focus().toggleUnderline().run()} className={editor.isActive('underline') ? 'is_active' : ''}>
          <FaUnderline />
        </button>
        <button onClick={() => editor.chain().focus().toggleStrike().run()} className={editor.isActive('strike') ? 'is_active' : ''}>
          <FaStrikethrough />
        </button>
        <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={editor.isActive('bulletList') ? 'is_active' : ''}>
          <FaListUl />
        </button>
        <button onClick={() => editor.chain().focus().undo().run()}>
          <FaUndo />
        </button>
        <button onClick={() => editor.chain().focus().redo().run()}>
          <FaRedo />
        </button>
        <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
            className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
          >
            H1
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
            className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
          >
            H2
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
            className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
          >
            H3
          </button>
          {/* <select onChange={(e) => handleSetFontSize(e.target.value)}>
          <option value="12px">12px</option>
          <option value="14px">14px</option>
          <option value="16px">16px</option>
          <option value="18px">18px</option>
          <option value="20px">20px</option>
          <option value="24px">24px</option>
        </select> */}
      </div>
    </div>
  );
};

export default MenuBar;
