// LeftNav.tsx
import React from 'react';
import { Menu } from 'antd';
import { UserOutlined, FileTextOutlined, PictureOutlined, FontSizeOutlined } from '@ant-design/icons';

interface LeftNavProps {
  onSelect: (key: string) => void;
}

const LeftNav: React.FC<LeftNavProps> = ({ onSelect }) => {
  return (
    <Menu
      mode="vertical"
      defaultSelectedKeys={['avatar']}
      onClick={(e) => onSelect(e.key)}
      style={{ height: '100%', borderRight: 0 }}
    >
      <Menu.Item key="avatar" icon={<UserOutlined />}>
        Avatar
      </Menu.Item>
      <Menu.Item key="background" icon={<PictureOutlined />} >
        Background
      </Menu.Item>
      <Menu.Item key="voice" icon={<FontSizeOutlined />} >
        Voice
      </Menu.Item>
      <Menu.Item key="newsInput" icon={<FileTextOutlined />}>
       News Feed
      </Menu.Item>
    </Menu>
  );
};

export default LeftNav;
