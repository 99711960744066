import { useState } from "react";
import { Button, Form, Input } from "antd";
//import siteLogo from "../assets/images/da-logo.svg";
import { useNavigate } from "react-router-dom";
//import useAppNotification from "../hooks/notificationHooks";
import { fireSingIn } from "./CommonFirebase";
import { setTokenCookie } from "./useAuthenticate";

export default function UserLogin() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const handleLogin = async () => {
        try {
            await form.validateFields();
            setLoading(true);
            const { email, password } = form.getFieldsValue();
            const auth: any = await fireSingIn(email, password);
            // console.log(auth);
            if (!auth) throw Error("Account is inactive!");
            const storePayload = {
                email: auth?.email ?? "",
                uid: auth?.uid ?? "",
            };
            setTokenCookie(auth.accessToken, storePayload);

            await new Promise((resolve) => {
                setTimeout(() => {
                    setLoading(true);
                    navigate("/MockupGenerator");
                    resolve("");
                }, 100);
            });
        } catch (error: any) {
            //useAppNotification(error);
            setLoading(false);
        }
    };

    return (
        <div className="loginWrapper">
            {/* <h1>
                <img src={siteLogo} alt="Da CV Screener" />
            </h1> */}
            <div className="loginBlk">
                <h2>Welcome back!</h2>
                <Form form={form} layout="vertical">
                    <Form.Item
                        rules={[{ required: true }, { type: "email" }]}
                        label="Email Address"
                        name="email"
                    >
                        <Input placeholder="Enter your email address" />
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true }]}
                        name="password"
                        label="Password"
                        hasFeedback
                    >
                        <Input.Password placeholder="Enter your password" />
                    </Form.Item>
                    <div className="forgotWrap">
                        <div>Forgot Password</div>
                    </div>
                    <Button
                        onClick={handleLogin}
                        type="primary"
                        htmlType="submit"
                        className="fullWidth"
                        loading={loading}
                        disabled={loading}
                    >
                        Login
                    </Button>
                </Form>
            </div>
        </div>
    );
}
