import React from 'react';
import { useStore } from 'zustand';
import { NewsAnchorStore } from '../components/store/NewsAnchor.store';

interface VoiceStyle {
  label: string;
  value: string;
  emoji: string;
}

const voiceStyles: VoiceStyle[] = [
  { label: 'Excited', value: 'excited', emoji: '😃' },
  { label: 'Friendly', value: 'friendly', emoji: '😊' },
  { label: 'Sad', value: 'sad', emoji: '😢' },
  { label: 'Terrified', value: 'terrified', emoji: '😱' },
  { label: 'Angry', value: 'angry', emoji: '😡' },
];

const VoiceStyleSelect: React.FC = () => {
    const { payload, updatePayload,masterPresenterResponseArray } = useStore(NewsAnchorStore);
    const [selectedStyle, setSelectedStyle] = React.useState<string>(payload.voice_style);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newStyle = event.target.value;
    setSelectedStyle(newStyle);
    updatePayload({ voice_style: newStyle }); // Update Zustand store
  };

  const selectedEmoji = voiceStyles.find(style => style.value === selectedStyle)?.emoji;
console.log(masterPresenterResponseArray)
  return (
    <div className="voiceStyleContainer">
      <select className="voiceStyleSelect" value={selectedStyle} onChange={handleChange}>
        {masterPresenterResponseArray?.voice_style?.map((style:any) => (
          <option key={style} value={style}>
            {style}
          </option>
        ))}
      </select>
      <div className="selectedEmoji">
        {selectedEmoji}
      </div>
    </div>
  );
};

export default VoiceStyleSelect;
