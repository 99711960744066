import { create } from "zustand"
interface IModalData {
  shouldShow: boolean;
  modalHeader?: React.JSX.Element;
  modalBody?: React.JSX.Element;
  modalFooter?: React.JSX.Element;
}
export const MockupStore = create((set) => ({
    projectPageLists:{},
    activePageId:0,
    requirementApiStatus:false,
    modalState: null,
  setProjectPageLists :(projectListValue:any)=>{
      console.log(projectListValue)
      set({projectPageLists:projectListValue})
  },
  setActivePageId:(pageIdValue:number)=>
  {
    set({activePageId:pageIdValue})
  },
  setRequirementApiStatus:(value:boolean)=>{
      set({requirementApiStatus:value})
  },
  
  setModalState: (value: IModalData) => set({ modalState: value })
}))