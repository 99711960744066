import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import useAppNotification from "../hooks/notificationHooks";
import { getTokenCookie } from "./useAuthenticate";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const navigate = useNavigate()
    const handleGuard = () => {
        const user = getTokenCookie();
        if (!user?.rawToken) {
            navigate("/login");
            //useAppNotification({ message: "You need to login!" });
        }
    };

    useEffect(() => {
        handleGuard();
    }, []); 

    return <>{children}</>;
};

export default ProtectedRoute;
