import React, { Fragment, useEffect, useState } from "react";
import LeftNav from "./LeftNav";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MockupStore } from "../store/Mockup.store";
import { Dropdown, MenuProps } from "antd";

export interface IMainWrapper {
  children?: React.ReactNode;
}

const Feitems:any = [
  {
    label: <p>React With Typescript</p>,
    key: "0",
  },
  {
    label: <p>Vue js</p>,
    key: "1",
  },
  {
    label: <p>Next js</p>,
    key: "2",
  },
];

const Beitems: any = [
  {
    label: <p>Python with Flask</p>,
    key: "0",
  },
  {
    label: <p>Nest Js</p>,
    key: "1",
  },
  {
    label: <p>Python with FastAPI</p>,
    key: "2",
  },
];

const MainWrapper: React.FC<IMainWrapper> = ({ children }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  let { projectId, threadId } = useParams();
  const { projectPageLists, setProjectPageLists, activePageId, requirementApiStatus } = MockupStore((state: any) => state);

  useEffect(() => {
    const currentPath: any = location.pathname;
    setActiveTab(currentPath);
    console.log(currentPath);
  }, [location]);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('Clicked menu item:', e);
    if (!requirementApiStatus) {
      navigation(`/MockupGenerator/frontend/${projectId}/${threadId}/${e.key}`);
    }
  };
  const handleBeMenuClick: MenuProps['onClick'] = (e) => {
    console.log('Clicked menu item:', e);
    if (!requirementApiStatus) {
      navigation(`/MockupGenerator/backend/${projectId}/${threadId}/${e.key}`);
    }
  }
  return (
    <Fragment>
      <div className="mainContainer">
        <div>
          <LeftNav />
        </div>
        <div className="mainContentDiv">
          <div className="userStoryContainer">
            <div className="tabNavigation">
              <div
                className={`tab ${activeTab.includes("requirement") ? "active" : ""}`}
                onClick={() => navigation(`/MockupGenerator/requirement/${projectId}/${threadId}`)}
              >
                Requirement
              </div>
              <div
                className={`${((activeTab.includes("userStory") || activeTab.includes("requirement")||activeTab.includes("backend")||activeTab.includes("frontend")) && requirementApiStatus) ? "tabDisabled" : "tab"} ${activeTab.includes("htmlMockup") ? "active" : ""}`}
                onClick={() => requirementApiStatus ? undefined : navigation(`/MockupGenerator/htmlMockup/${projectId}/${threadId}`)}
              >
                HTML Mockup
              </div>
              <div
                className={`${((activeTab.includes("htmlMockup") || activeTab.includes("requirement") || activeTab.includes("backend")||activeTab.includes("frontend")) && requirementApiStatus) ? "tabDisabled" : "tab"} ${activeTab.includes("userStory") ? "active" : ""}`}
                onClick={() => requirementApiStatus ? undefined : navigation(`/MockupGenerator/userStory/${projectId}/${threadId}`)}
              >
                User Story
              </div>
              <Dropdown
                disabled={requirementApiStatus}
                menu={{
                  items:Feitems,
                  onClick: handleMenuClick,
                }}
                trigger={["click"]}
              >
              <div
                className={`${((activeTab.includes("htmlMockup") || activeTab.includes("requirement") || activeTab.includes("backend") || activeTab.includes("userstory")) && requirementApiStatus) ? "tabDisabled" : "tab"} ${activeTab.includes("frontend") ? "active" : ""}`}
              >
                Frontend
              </div>
              </Dropdown>
              <Dropdown
                disabled={requirementApiStatus}
                menu={{
                  items:Beitems,
                  onClick: handleBeMenuClick,
                }}
                trigger={["click"]}
              >
              <div
                className={`${((activeTab.includes("htmlMockup") || activeTab.includes("requirement") || activeTab.includes("frontend") || activeTab.includes("userstory")) && requirementApiStatus) ? "tabDisabled" : "tab"} ${activeTab.includes("backend") ? "active" : ""}`}
              >
                Backend
              </div>
              </Dropdown>
            </div>
            <div className="mainWrapperChildContainer">{children}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MainWrapper;
