import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useStore } from 'zustand';
import { NewsAnchorStore } from '../components/store/NewsAnchor.store';

interface Presenter {
  driver_id: string;
  gender: string;
  image_url: string;
  name: string;
  presenter_id: string;
  talking_preview_url: string;
  thumbnail_url: string;
}

const AvatarSubNav: React.FC = () => {
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>("oqNen3Q3aS");
  // const [presenters, setPresenters] = useState<{ female: Presenter[]; male: Presenter[] }>({
  //   female: [],
  //   male: [],
  // });
  const { payload, updatePayload,setMasterPresenterResponseArray,masterPresenterResponseArray } = useStore(NewsAnchorStore);


  // useEffect(() => {
  //   const fetchPresenters = async () => {
  //     try {
  //       const response = await axios.post('https://devapi.usg.digitalavenues.net/get-pre-defined-presenters-details');
  //       setPresenters(response.data.presenters);
  //     } catch (error) {
  //       console.error('Error fetching presenters:', error);
  //     }
  //   };

  //   fetchPresenters();
  // }, []);

  const handleAvatarClick = (driver_id: string, presenter_id: string,img_url:string) => {
    setSelectedAvatar(driver_id);
    updatePayload({ driver_id, presenter_id,img_url }); 
  };

  const renderRows = (presenters: Presenter[]) => {
    const rows = [];
    for (let i = 0; i < presenters?.length; i += 2) {
      rows.push(
        <div key={i} style={{ display: 'flex', gap: '10px' }}>
          {presenters.slice(i, i + 2).map((presenter, index) => (
            <img
              key={index}
              src={presenter.image_url}
              alt={presenter.name}
              onClick={() => handleAvatarClick(presenter.driver_id, presenter.presenter_id,presenter.image_url)}
              style={{
                width: '48%',
                borderRadius: '8px',
                border: payload.driver_id === presenter.driver_id ? '2px solid blue' : 'none',
                cursor: 'pointer',
              }}
            />
          ))}
        </div>
      );
    }
    return rows;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
      {renderRows(masterPresenterResponseArray?.presenters?.female)}
      {renderRows(masterPresenterResponseArray?.presenters?.male)}
    </div>
  );
};

export default AvatarSubNav;
