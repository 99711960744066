import React, { useEffect, useState } from 'react';
import LeftNav from './LeftNav';
import AvatarSubNav from './AvatarSubNav';
import axios from 'axios';
import { Button, Skeleton } from 'antd';

import NewsFeed from './NewsFeed';
import Voice from './VoiceCard';
import VoiceAccordion from './VoiceAccordion';
import { Background } from './Background';
import { NewsAnchorStore } from '../components/store/NewsAnchor.store';
import { useStore } from 'zustand';

const NewsAnchorMain: React.FC = () => {
  const [selectedNav, setSelectedNav] = useState('avatar');
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null);
 const[activeIndex,setActiveIndex]=useState<number>(-1);
 const { payload, updatePayload,setMasterPresenterResponseArray,masterPresenterResponseArray } = useStore(NewsAnchorStore);
  const handleSelectAvatar = (name: string) => {
    setSelectedAvatar(name);
  };

  const handleGenerateVideo = async (title: string,index:number) => {
    console.log(payload)
    setActiveIndex(index)
    setLoading(true);
    setVideoUrl(null); // Clear previous video if any
    try {
      const response = await axios.post('https://devapi.usg.digitalavenues.net/generate-video-from-news-text-with-presenter', {
        presenter_id: payload.presenter_id,
        driver_id: payload.driver_id,
        news_text: title,
        subtitles_status: false,
        voice_id: payload.voice_id,
        video_format: "mp4",
        voice_style: payload.voice_style,
        bg_color: payload.bg_color  
      });

      setVideoUrl(response.data.video_url);
    } catch (error) {
      console.error('Error generating video:', error);
    } finally {
      setLoading(false);
      setActiveIndex(-1)
    }
  };

  const renderSubNav = () => {
    switch (selectedNav) {
      case 'avatar':
        return <AvatarSubNav />;
      case 'newsInput':
        return <NewsFeed onPlayClick={handleGenerateVideo} activeIndex={activeIndex} />;
      case 'voice':
        return <VoiceAccordion/>  
        case 'background':
          return <Background/> 
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchPresenters = async () => {
      try {
        const response = await axios.post('https://devapi.usg.digitalavenues.net/get-pre-defined-presenters-details');
        setMasterPresenterResponseArray(response.data);
      } catch (error) {
        console.error('Error fetching presenters:', error);
      }
    };

    fetchPresenters();
  }, []);

  return (
    <div className="appContainer">
      <div className="leftNav">
        <LeftNav onSelect={setSelectedNav} />
      </div>
      <div className="subNav">
        {renderSubNav()}
      </div>
      <div className="mainContent">
        <div className="contentPreview">
          {loading ? (
            <Skeleton.Input active={true} style={{ width: 800, height: 600 }} />
          ) :videoUrl ? (
            <div className="videoContainer">
              <video controls src={videoUrl} autoPlay className="videoPreview" />
              <div className="videoShadowOverlay" />
            </div>
          ):<div  className='presenterViewWrap'>
          <div className='presenterInfo'>
            Voice:<b>{payload.voice_id}</b>,  Voice style:<b>{payload.voice_style}</b>
          </div>
          <img className='presenterImagePreview' src={payload.img_url} style={{background:payload.bg_color}}/></div>}
         
        </div>
      </div>
    </div>
  );
};

export default NewsAnchorMain;
