export const downloadFile = (fileContent:any,fileType:string) => {
    console.log(fileContent)
    const blob = new Blob([fileContent], { type: fileType });
    const url = URL.createObjectURL(blob);
  
    const a = document.createElement('a');
    a.href = url;
    a.download = "singleFile";
    
    document.body.appendChild(a);
    a.click();
    
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };