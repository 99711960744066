import {
  DownCircleOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  RedoOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { MockupStore } from "../store/Mockup.store";
import mockupGeneratorService from "../../services/mockupGenerator.service";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import TextArea from "antd/es/input/TextArea";
import { Button, Carousel, DrawerProps, Dropdown, MenuProps } from "antd";
import HtmlEditor from "../AceEditor/HtmlEditor";
import CssEditor from "../AceEditor/CssEditor";
import JsEditor from "../AceEditor/JsEditor";
import JSZip from "jszip";
import saveAs from "file-saver";
import RightDrawer from "./RightDrawer";
import CenteredModal from "./CenteredModal";
import TemplateGallery from "./TemplateGallery";
import TemplateAdditionalForm from "./TemplateAdditionalForm";

const items: MenuProps["items"] = [
  {
    label: <p>Edit Html Code</p>,
    key: "0",
  },
  {
    label: <p>Edit Css Code</p>,
    key: "1",
  },
  {
    label: <p>Edit Js Code</p>,
    key: "2",
  },
];

const HtmlMockup = () => {
  const {
    projectPageLists,
    setProjectPageLists,
    activePageId,
    setRequirementApiStatus,
    requirementApiStatus,
    modalState,
    setModalState
  } = MockupStore((state: any) => state);
  const { projectId, threadId } = useParams();
  const [whichisEditable, setWhichisEditable] = useState<string>("3"),
    [htmlUpdatedCodeValue, setHtmlUpdatedCodeValue] = useState<string>(""),
    [cssUpdatedCodeValue, setCssUpdatedCodeValue] = useState(""),
    [jsUpdatedCodeValue, setJsUpdatedCodeValue] = useState("");
    const [isRightDrawerOpen,setIsRightDrawerOpen] = useState<any>(false);
    const [rightDrawerPlacement, setRightDrawerPlacement] = useState<DrawerProps['placement']>('right');
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
    const [templateToggaleView, setTemplateToggaleView] = useState(true);
  

  const generateFeCode = async () => {
    const updatedPages1 = projectPageLists.saved_page_list.map(
      (page: any) => {
        if (page.page_id === activePageId) {
          return {
            ...page,
            html_code: null,
            css_code: null,
            js_code: null,
          };
        }
        return page;
      }
    );
    setProjectPageLists({
      ...projectPageLists,
      saved_page_list: updatedPages1,
    });
    setHtmlUpdatedCodeValue("");
    setCssUpdatedCodeValue("");
    setJsUpdatedCodeValue("");
    setRequirementApiStatus(true);
    let codeChunks = "";
    const payload = {
      page_id: activePageId,
      page_title:
        projectPageLists.saved_page_list.find(
          (e: any) => e.page_id === activePageId
        )?.page_title || null,
      thread_id: threadId,
    };
    try {
      const response: any = await mockupGeneratorService.generateCode(payload);
      console.log("html", response.body);
      const reader1: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      while (true) {
        const result1: any = await reader1?.read();
        console.log(result1);
        if (result1) {
          const { done, value } = result1;
          if (done) {
            const codeSections = extractAllCodeSections(codeChunks);
            console.log(codeSections)
            const updatedPages = projectPageLists.saved_page_list.map(
              (page: any) => {
                if (page.page_id === activePageId) {
                  return {
                    ...page,

                    html_code:
                      codeSections[0]?.language.replace(/^html/, "").trim() ||
                      "",
                    css_code: codeSections[1]?.language.replace(/^css/, "") || "",
                    js_code: codeSections[2]?.language.replace(/^javascript\s*/, '') || "",
                  };
                }
                return page;
              }
            );
            setProjectPageLists({
              ...projectPageLists,
              saved_page_list: updatedPages,
            });

            break;
          }
          codeChunks += new TextDecoder().decode(value);
          const stableCodeChunks = codeChunks;
          console.log(projectPageLists)
          const updatedPages = projectPageLists.saved_page_list.map((page: any) => {
            if (page.page_id === activePageId) {
              return {
                ...page,
                html_code: stableCodeChunks,
                css_code:"",
                js_code:""

              };
            }
            return page;
          });

          setProjectPageLists({
            ...projectPageLists,
            saved_page_list: updatedPages,
          });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequirementApiStatus(false);
    }
  };


  const handlePageWiseZipDownload = async (value: number) => {
    const page = projectPageLists?.saved_page_list?.find(
      (p: any) => p.page_id === value
    );
  
    if (!page) {
      console.error("Page not found");
      return;
    }
  
    const zip = new JSZip();
    zip.file(`${page.page_title}.html`, page.html_code.replace("```", ""));
    zip.file(`style.css`, page.css_code.replace("```", ""));
    zip.file(`script.js`, page.js_code.replace("```", ""));
  
    try {
      const logoBlob = await handleGetLogo(); 
      if (logoBlob) {
        zip.file( projectPageLists?.project_details?.logo_name !== undefined
          ? projectPageLists?.project_details?.logo_name
          : projectPageLists?.project?.logo_name, logoBlob);
      } else {
        console.error("Logo not fetched correctly");
      }
  
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `${page.page_title}.zip`);
    } catch (error) {
      console.error("Error generating ZIP file:", error);
    }
  };
  
  const handleGetLogo = async (): Promise<Blob | null> => {
    console.log("first")
    try {
      const payload = {
        logo_name: projectPageLists?.project_details?.logo_name !== undefined
        ? projectPageLists?.project_details?.logo_name
        : projectPageLists?.project?.logo_name
        ,
      };
      const response: any = await mockupGeneratorService.getLogo(payload);
  
      if (!response) {
        throw new Error("No response from getLogo API");
      }
  
      // Fetch the image from the signed URL as a blob
      console.log(response)
      const imageResponse = await fetch(response.data.logo_url);
      if (!imageResponse.ok) {
        throw new Error(`Failed to fetch image: ${imageResponse.statusText}`);
      }
      console.log(imageResponse)
      const imageBlob = await imageResponse.blob();
      console.log(imageBlob)
      return imageBlob;
    } catch (error) {
      console.error("Error fetching logo:", error);
      return null; // Return null in case of an error
    }
  };
  

  const handlePageWiseZipRender = (value: number) => {
    const page = projectPageLists?.saved_page_list?.find(
      (p: any) => p.page_id === value
    );

    if (!page) {
      console.error("Page not found");
      return;
    }

    // Create blobs for each file
    const htmlBlob = new Blob([page.html_code.replace("```", "")], {
      type: "text/html",
    });
    const cssBlob = new Blob([page.css_code.replace("```", "")], {
      type: "text/css",
    });
    const jsBlob = new Blob([page.js_code.replace("```", "")], {
      type: "application/javascript",
    });

    // Create object URLs for each blob
    const htmlUrl = URL.createObjectURL(htmlBlob);
    const cssUrl = URL.createObjectURL(cssBlob);
    const jsUrl = URL.createObjectURL(jsBlob);

    // Generate a new HTML file with links to the CSS and JS blobs
    const newHtmlContent = `
      <!DOCTYPE html>
      <html>
      <head>
        <title>${page.page_title}</title>
        <link rel="stylesheet" type="text/css" href="${cssUrl}">
      </head>
      <body>
        ${removeHeader(page.html_code.replace("```", ""), page)}
        <script src="${jsUrl}"></script>
      </body>
      </html>
    `;

    // Create a blob for the new HTML content
    const newHtmlBlob = new Blob([newHtmlContent], { type: "text/html" });
    const newHtmlUrl = URL.createObjectURL(newHtmlBlob);

    // Open the new HTML content in a new tab
    window.open(newHtmlUrl, "_blank");

    // Clean up object URLs after some time (optional)
    setTimeout(() => {
      URL.revokeObjectURL(htmlUrl);
      URL.revokeObjectURL(cssUrl);
      URL.revokeObjectURL(jsUrl);
      URL.revokeObjectURL(newHtmlUrl);
    }, 30000);
  };

  const removeHeader = (html: string, page: any) => {
    const regex =
      /<!--.*?\.html -->\s*<!DOCTYPE html>\s*<html lang="en">\s*<head>\s*<meta charset="UTF-8">\s*<meta name="viewport" content="width=device-width, initial-scale=1.0">\s*<title>.*?<\/title>\s*<link rel="stylesheet" href="styles\.css">\s*<\/head>\s*<body>/;
    return html.replace(regex, "");
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    setHtmlUpdatedCodeValue("");
    setCssUpdatedCodeValue("");
    setJsUpdatedCodeValue("");
    console.log(e.key);
    setWhichisEditable(e.key);
  };
  const handleHtmlEditorChange = (value: any) => {
    console.log(value);
  };
  function extractAllCodeSections(text: string) {
    const sections = [];
    let start = 0;

    while (true) {
      const startMarkerIndex = text.indexOf("```", start);
      if (startMarkerIndex === -1) break;

      const languageStart = startMarkerIndex + 3;
      const endMarkerIndex = text.indexOf("```", languageStart);
      if (endMarkerIndex === -1) break;

      const language = text.substring(languageStart, endMarkerIndex).trim();
      const codeStart = endMarkerIndex + 3;
      const code = text.substring(codeStart, startMarkerIndex).trim();

      if (language.toLowerCase() !== "html") {
        // Check if language is not HTML
        sections.push({ language, code });
      }
      start = codeStart;
    }

    return sections
  }

  const handleupdatCode = async () => {
    console.log(projectPageLists);
    try {
      const payload = {
        project_id: projectId,
        project_page_id: activePageId,
        html_code: htmlUpdatedCodeValue ? htmlUpdatedCodeValue : undefined,
        css_code: cssUpdatedCodeValue ? cssUpdatedCodeValue : undefined,
        js_code: jsUpdatedCodeValue ? jsUpdatedCodeValue : undefined,
      };
      const response = await mockupGeneratorService.updateProjectPageDetails(
        payload
      );
      if (response) {
        const updatedPages = projectPageLists.saved_page_list.map(
          (page: any) => {
            if (page.page_id === activePageId) {
              return {
                ...page,

                ...(htmlUpdatedCodeValue !== undefined &&
                  htmlUpdatedCodeValue !== "" && {
                  html_code: htmlUpdatedCodeValue,
                }),
                ...(cssUpdatedCodeValue !== undefined &&
                  cssUpdatedCodeValue !== "" && {
                  css_code: cssUpdatedCodeValue,
                }),
                ...(jsUpdatedCodeValue !== undefined &&
                  jsUpdatedCodeValue !== "" && { js_code: jsUpdatedCodeValue }),
              };
            }
            return page;
          }
        );

        setHtmlUpdatedCodeValue("");
        setCssUpdatedCodeValue("");
        setJsUpdatedCodeValue("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCssEditorChangeValue = (data: string) => {
    data && setCssUpdatedCodeValue(data);
    const updatedPages = projectPageLists.saved_page_list.map((page: any) => {
      if (page.page_id === activePageId) {
        return {
          ...page,

          css_code: data,
        };
      }
      return page;
    });
    setProjectPageLists({
      ...projectPageLists,
      saved_page_list: updatedPages,
    });
  };
  const handleJsEditorChangeValue = (data: string) => {
    data && setJsUpdatedCodeValue(data);
    const updatedPages = projectPageLists.saved_page_list.map((page: any) => {
      if (page.page_id === activePageId) {
        return {
          ...page,

          js_code: data,
        };
      }
      return page;
    });
    setProjectPageLists({
      ...projectPageLists,
      saved_page_list: updatedPages,
    });
  };
  const handleHtmlEditorChangeValue = (data: string) => {
    data && setHtmlUpdatedCodeValue(data);
    console.log(projectPageLists)
    const updatedPages = projectPageLists.saved_page_list.map((page: any) => {
      if (page.page_id === activePageId) {
        return {
          ...page,

          html_code: data,
        };
      }
      return page;
    });
    setProjectPageLists({
      ...projectPageLists,
      saved_page_list: updatedPages,
    });
  };

  function handleRegenerateCode() {
    setModalState(
      {
        modalBody: (<>
        {templateToggaleView?
        
          <TemplateGallery
          iscarouselView={true}
          setTemplateToggaleView={setTemplateToggaleView}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate} 
          />
         :
          <TemplateAdditionalForm selectedTemplateId={selectedTemplate}/>}
          </>
        ),
        isModalOpen:true
      }
    );
  }

  useEffect(()=>{modalState?.isModalOpen && handleRegenerateCode()},[selectedTemplate,templateToggaleView])


  const handleCancel = () => {
    setHtmlUpdatedCodeValue("");
    setCssUpdatedCodeValue("");
    setJsUpdatedCodeValue("");
    setWhichisEditable("3");
  };

  useEffect(() => {
    setHtmlUpdatedCodeValue("");
    setCssUpdatedCodeValue("");
    setJsUpdatedCodeValue("");
    activePageId &&
      (projectPageLists?.saved_page_list?.find(
        (e: any) => e.page_id === activePageId
      )?.html_code === null ||
        projectPageLists?.saved_page_list?.find(
          (e: any) => e.page_id === activePageId
        )?.html_code === undefined) &&
      generateFeCode();
  }, [activePageId]);
  console.log(projectPageLists.saved_page_list)
 
  return (
    <>
    
    <CenteredModal/>
      {projectPageLists?.saved_page_list?.map((e: any) =>
        e.page_id === activePageId ? (
          <div className="requirementContainer">
            <div className="htmlMockupButtonWrap">
              <Dropdown
                disabled={requirementApiStatus}
                menu={{
                  items,
                  onClick: handleMenuClick,
                }}
                trigger={["click"]}
              >
                <EditOutlined />
              </Dropdown>
              <EyeOutlined disabled={requirementApiStatus} onClick={() => requirementApiStatus ? undefined : handlePageWiseZipRender(e.page_id)} />
              <DownloadOutlined
                onClick={() => handlePageWiseZipDownload(e.page_id)}
              />
              {(whichisEditable === "3") &&
                <SyncOutlined
                  onClick={()=>requirementApiStatus ? undefined : generateFeCode()}
                  spin={requirementApiStatus}
                />
              }
              <Button
                className="save-button"
                onClick={handleupdatCode}
                disabled={
                  !htmlUpdatedCodeValue &&
                  !cssUpdatedCodeValue &&
                  !jsUpdatedCodeValue
                }
              >
                Save
              </Button>
              <Button className="cancelbutton" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
            {whichisEditable === "0" ? (
              <>
                <HtmlEditor
                  data={`${e?.css_code
                      ? e?.html_code
                      : extractAllCodeSections(e?.html_code)[0].language
                    }\n`}
                  onChangeProp={(e) => {
                    handleHtmlEditorChangeValue(e);
                  }}
                  index={100}
                />
              </>
            ) : whichisEditable === "1" ? (
              <>
                <CssEditor
                  data={`${e?.css_code
                      ? e?.css_code
                      : extractAllCodeSections(e?.html_code)[1].language
                    }`}
                  onChangeProp={(e) => handleCssEditorChangeValue(e)}
                  index={100}
                />
              </>
            ) : whichisEditable === "2" ? (
              <>
                <JsEditor
                  data={`${e?.js_code
                      ? e?.js_code
                      : extractAllCodeSections(e?.html_code)[2].language
                    }\n`}
                  onChangeProp={(e) => {
                    handleJsEditorChangeValue(e);
                  }}
                  index={100}
                />
              </>
            ) : (
              <div className="htmlMockupCode">
                <ReactMarkdown>{`
\`\`\`
${e?.html_code || ""}
\`\`\`

\`\`\`
${e?.js_code || ""}
\`\`\`

\`\`\`
${e?.css_code || ""}
\`\`\`
`}</ReactMarkdown>
              </div>
            )}
          </div>
        ) : null
      )}
    </>
  );
};

export default HtmlMockup;
