import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { memo } from "react";
import JsEditor from "../AceEditor/JsEditor";
import { downloadFile } from "../common";
import { MockupStore } from "../store/Mockup.store";

export interface IFeedbackModal {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  data: string;
  children?: React.ReactNode;
  handleRegenerate: () => void;
}

const ViewCodeModal: React.FC<IFeedbackModal> = ({
  title,
  open,
  setOpen,
  data,
  children,
  handleRegenerate,
}) => {
  const { requirementApiStatus } = MockupStore((state: any) => state);

  return (
    <>
      {" "}
      <Modal
        title={title}
        centered
        open={open}
        width="100%"
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div className="downLoadJsButton">
          <div className="viewCodeModalSyncIcon">
            <SyncOutlined
              onClick={requirementApiStatus ? undefined : handleRegenerate}
              spin={requirementApiStatus}
            />
          </div>
          <Button
            disabled={requirementApiStatus}
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => downloadFile(data, "application/javascript")}
          >
            Download
          </Button>
        </div>
        {children}
      </Modal>
    </>
  );
};

export default memo(ViewCodeModal);
