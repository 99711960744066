export enum RoutesEnum {
    GENERATE_ALL_SECTION="/generate-all-the-possible-sections",
    GET_PAGE_WISE_RD="/get-page-wise-rd",
    GET_PAGE_WISE_CODE="/get-page-wise-frontend-code-generation",
    SAVE_PAGE_LIST="/save-project-page-list",
    SAVE_STUDENT_PROFILE="/save-student-profile-details",
    GPT_ASSESMENT_QUIZ="/get-explanation-with-image",
    GET_PROJECT_LIST="/get-project-list",
    GENERATE_TOTAL_CSS="/generate-total-css-code",
    GENERATE_TOTAL_JS="/generate-total-js-code",
    GET_PROJECT_DETAILS="/get-project-details",
    UPDATE_PROJECT_PAGE_DETAILS="/save-or-update-project-page-details",
    DELETE_PROJECT_FILE="/delete-project",
    GET_PAGE_LIST="",
    DELETE_PROJECT_PAGE="/delete-project-page",
    GENERATE_USER_STORY="/gen-user-story",
   GENERATE_ACCEPTANCE_CRITERIA="/gen-acceptance-criteria",
   GENERATE_TEST_CASES="/gen-test-cases",
   GET_USER_STORY_DETAILS="/get-user-story-details",
   DELETE_USER_STORY="/delete-user-story",
   UPDATE_USER_STORY="/save-or-update-user-story-details",
   GET_LOGO_URL="/get-image-url",
   UPDATE_PROJECT_DETAILS="/save-or-update-project-details",
   DELETE_ALL_CODE_FROM_PROJECT="/delete-all-code-from-project",
   GENRATE_PAGEWISE_BACKEND_CODE="/generate-page-wise-backend-code",
   GENRATE_PAGEWISE_FRONTEND_CODE="/generate-page-wise-frontend-code-with-framework"
}