import { initializeApp } from "firebase/app";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import { removeTokenCookie } from "./useAuthenticate"

// const firebaseDomain = import.meta.env.VITE_FIREBASE_DOMAIN;
// const firebaseConfig = {
//     apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
//     authDomain: firebaseDomain + ".firebaseapp.com",
//     projectId: firebaseDomain,
//     storageBucket: firebaseDomain + ".appspot.com",
//     messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGE_SENDER,
//     appId: import.meta.env.VITE_FIREBASE_APP_ID,
// };

const firebaseConfig = {

    apiKey: "AIzaSyBBvmGoxB5pd_iF4P-EWf3ABA96uyGnqR4",
  
    authDomain: "da-labs-398108.firebaseapp.com",
  
    projectId: "da-labs-398108",
  
    storageBucket: "da-labs-398108.appspot.com",
  
    messagingSenderId: "95497870741",
  
    appId: "1:95497870741:web:ce8045a923c3290ae1ea9c",
  
    measurementId: "G-RPC7DSZXF4"
  
  };
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const fireSignup = async (email: string, password: string) => {
    return await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            return user;
        })
        .catch((error) => {
            if (error.code === "auth/email-already-in-use") return auth.currentUser;
            else throw error;
        });
};

const fireSingIn = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            return user;
        })
        .catch((error: any) => {
            if (error.code === "auth/invalid-login-credentials")
                throw Error("Invalid password");

            throw error;
        });
};

const fireSignout = async () => {
    removeTokenCookie();
    return signOut(auth);
};

const fireGetCurrentUser = async () => {
    return await auth?.currentUser?.getIdToken(true);
};

export { app, fireSignup, fireSingIn, fireGetCurrentUser, fireSignout, auth };


