import { create } from "zustand";

interface Payload {
  presenter_id: string;
  driver_id: string;
  news_text: string;
  subtitles_status: boolean;
  voice_id: string;
  video_format: string;
  voice_style: string;
  bg_color: string;
  img_url:string
}

interface NewsAnchorStoreState {
  payload: Payload;
  updatePayload: (update: Partial<Payload>) => void;
  masterPresenterResponseArray: any; // Adjust type if needed
  setMasterPresenterResponseArray: (value: any) => void; // Adjust type if needed
}

export const NewsAnchorStore = create<NewsAnchorStoreState>((set) => ({
  payload: {
    presenter_id: "lily-akobXDF34M",
    driver_id: "oqNen3Q3aS",
    news_text: "",
    subtitles_status: false,
    voice_id: "en-US-JaneNeural",
    video_format: "mp4",
    voice_style: "excited",
    bg_color: "#FFB6C1",
    img_url:"https://clips-presenters.d-id.com/lily/akobXDF34M/oqNen3Q3aS/image.png"
  },
  updatePayload: (update: Partial<Payload>) => set(state => ({
    payload: {
      ...state.payload,
      ...update
    }
  })),
  masterPresenterResponseArray: [{}],
  setMasterPresenterResponseArray: (value: any) => set({
    masterPresenterResponseArray: value
  })
}));
