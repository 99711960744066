import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import NavItem from "./NavItem";
import SearchBox from "./SearchBox";
import {
  BgColorsOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CodeFilled,
  DownloadOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { MockupStore } from "../store/Mockup.store";
import { useParams } from "react-router-dom";
import mockupGeneratorService from "../../services/mockupGenerator.service";
import { Button, Dropdown, Input, MenuProps, Spin, Tooltip } from "antd";
import ViewCodeModal from "./ViewCodeModal";
import CssEditor from "../AceEditor/CssEditor";
import JsEditor from "../AceEditor/JsEditor";
import JSZip from "jszip";
import saveAs from "file-saver";


const items: MenuProps["items"] = [
  {
    label: <p>Generate CSS Code</p>,
    key: "0",
  },
  {
    label: <p>Generate JS Code</p>,
    key: "1",
  },
];

const LeftNav: React.FC = () => {
  // const [activeItem, setActiveItem] = useState<number>();
  const {
    projectPageLists,
    setProjectPageLists,
    setActivePageId,
    activePageId,
    setRequirementApiStatus,
    requirementApiStatus
  } = MockupStore((state: any) => state);
  const [enableAddPage, setEnableAddPage] = useState(false);
  const [enableColorChange, setEnableColorChange] = useState(false);
  const [newPageTitleValue, setNewPageTitleValue] = useState("");
  const { projectId, threadId } = useParams();
  const [cssModalopen, setCssModalOpen] = useState(false);
  const [singleFileCssCode, setSingleFileCssCode] = useState("");
  const [continueResponse, setContiniueResponse] = useState(false);
  const cssEditorRef: any = useRef(null);
  const [singleFileJsCode, setSingleFileJsCode] = useState("");
  const [jsModalopen, setJsModalOpen] = useState(false);
  const [tempColorScheme, setTempColorScheme] = useState<any>(projectPageLists?.project?.project_color_scheme);
  const [isLoadingColorChange, setIsLoadingColorChange] = useState(false);
  const handleNavItemClick = async (pageId: number, pageTitle: string) => {
    setActivePageId(pageId);
  };

  const getProjectList = async () => {
    console.log("hi...");
    try {
      const payload = {
        thread_id: threadId,
        project_id: projectId,
      };
      const response: any = await mockupGeneratorService.getProjectDetails(
        payload
      );
      console.log("response", response.data);
      setProjectPageLists(response.data);
    } catch { }
  };

  const handleAddNewProjectPage = async () => {
    setEnableAddPage(false);
    if (newPageTitleValue) {
      try {
        const payload = {
          project_id: projectId,
          project_page_id: 0,
          page_title: newPageTitleValue,
          req_doc: "",
          html_code: "",
          css_code: "",
          js_code: "",
        };
        const response = await mockupGeneratorService.updateProjectPageDetails(
          payload
        );
        if (response.data.msg_id) {
          const newProjectPage = {
            created_on: "2024-07-05T06:35:56.921278",
            css_code: null,
            html_code: null,
            is_deleted: false,
            js_code: null,
            page_id: response.data.project_page_id,
            page_title: newPageTitleValue,
            req_doc: null,
            updated_on: null,
          };
          const updatedPageLists = [
            ...projectPageLists.saved_page_list,
            newProjectPage,
          ];
          setProjectPageLists({
            ...projectPageLists,
            saved_page_list: updatedPageLists,
          });
        }
      } catch { }
      finally {
        setNewPageTitleValue("")
      }
    }
  };
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "0") {
      console.log(projectPageLists?.project?.all_css_code)
      if (projectPageLists?.project?.all_css_code === undefined || projectPageLists?.project?.all_css_code === null) {
        generateSingleCssFile1();
      } else {
        setCssModalOpen(true);
        setSingleFileCssCode(projectPageLists?.project?.all_css_code);
        console.log(projectPageLists?.project?.all_css_code);
      }
    }
    else {
      if (projectPageLists?.project?.all_js_code === undefined || projectPageLists?.project?.all_js_code === null) {
        generateSingleJsFile();
      } else {
        console.log(projectPageLists?.project?.all_js_code);
        setJsModalOpen(true);
        setSingleFileJsCode(projectPageLists?.project?.all_js_code);
      }
    }
  };

  const generateSingleJsFile = async () => {
    setRequirementApiStatus(true);
    setSingleFileJsCode("");
    setJsModalOpen(true);
    try {
      const payload = {
        project_id: projectId,
        thread_id: threadId,
        continue_response: false,
      };
      let codeChunks = "";
      const response: any = await mockupGeneratorService.generateSingleJsFile(
        payload
      );
      const reader: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      while (true) {
        let codeChunks1 = "";
        const result: any = await reader?.read();
        if (result) {
          const { done, value } = result;
          if (done) {
            if (!/```[\s\S]*```/g.test(codeChunks)) {
              setContiniueResponse(true);
            }
            const updatedProjectPageLists = {
              ...projectPageLists,
              project: {
                ...projectPageLists.project,
                all_js_code: codeChunks.replace(/```javascript/g, ""),
              },
            };
            setProjectPageLists(updatedProjectPageLists);
            break;
          }
          codeChunks1 = new TextDecoder().decode(value);
          console.log(codeChunks1.length);
          codeChunks += new TextDecoder().decode(value);
          setSingleFileJsCode(codeChunks);
        }
      }
      setSingleFileJsCode(codeChunks.replace(/```javascript/g, ""));
    } catch {
    } finally {
      setRequirementApiStatus(false);
    }
  };

  const generateSingleCssFile1 = async () => {
    setRequirementApiStatus(true);
    setCssModalOpen(true);
    try {
      const payload = {
        project_id: projectId,
        thread_id: threadId,
        continue_response: false,
      };
      let codeChunks = "";
      const response: any = await mockupGeneratorService.generateSingleCssFile(
        payload
      );
      const reader1: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      while (true) {
        const result1: any = await reader1?.read();
        if (result1) {
          const { done, value } = result1;
          if (done) {
            if (!/```[\s\S]*```/g.test(codeChunks)) {
              setContiniueResponse(true);
            }
            const updatedProjectPageLists = {
              ...projectPageLists,
              project: {
                ...projectPageLists.project,
                all_css_code: codeChunks,
              },
            };
            setProjectPageLists(updatedProjectPageLists);

            break;
          }
          codeChunks += new TextDecoder().decode(value);
          setSingleFileCssCode(codeChunks);
        }
      }
    } catch {
    } finally {
      setRequirementApiStatus(false);
    }
  };

  const downloadZipFiles = async (cssContent: string, jsContent: string) => {
    console.log(projectPageLists);
    const zip = new JSZip();
    zip.file("script.js", jsContent.replace("```", ""));
    zip.file("style.css", cssContent);


    projectPageLists.saved_page_list.forEach(
      (pageWiseEle: any, index: number) => {
        zip.file(
          `${pageWiseEle?.page_title.replace(/\s+/g, "-").toLowerCase()}.html`,
          pageWiseEle.html_code
        );
      }
    );

    try {
      const logoBlob = await handleGetLogo();
      if (logoBlob) {
        zip.file(projectPageLists?.project_details?.logo_name !== undefined
          ? projectPageLists?.project_details?.logo_name
          : projectPageLists?.project?.logo_name, logoBlob);
      } else {
        console.error("Logo not fetched correctly");
      }
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, "files.zip");
    } catch (error) {
      console.error("Error generating ZIP file:", error);
    }
  };

  const handleCssEditorChange = (data: string) => {
    if (data) {
      setSingleFileCssCode(data);
    }
  };

  const handleGetLogo = async (): Promise<Blob | null> => {
    console.log("first")
    try {
      const payload = {
        logo_name: projectPageLists?.project_details?.logo_name !== undefined
          ? projectPageLists?.project_details?.logo_name
          : projectPageLists?.project?.logo_name
        ,
      };
      const response: any = await mockupGeneratorService.getLogo(payload);

      if (!response) {
        throw new Error("No response from getLogo API");
      }

      // Fetch the image from the signed URL as a blob
      console.log(response)
      const imageResponse = await fetch(response.data.logo_url);
      if (!imageResponse.ok) {
        throw new Error(`Failed to fetch image: ${imageResponse.statusText}`);
      }
      console.log(imageResponse)
      const imageBlob = await imageResponse.blob();
      console.log(imageBlob)
      return imageBlob;
    } catch (error) {
      console.error("Error fetching logo:", error);
      return null; // Return null in case of an error
    }
  };

  const handleContinueCssRespone = async () => {
    try {
      const payload = {
        project_id: projectId,
        thread_id: threadId,
        continue_response: continueResponse,
      };
      let codeChunks = "";
      const response: any = await mockupGeneratorService.generateSingleCssFile(
        payload
      );
      const reader: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      setContiniueResponse(false);
      while (true) {
        const result: any = await reader?.read();
        if (result) {
          const { done, value } = result;
          if (done) {
            if (!/```[\s\S]*```/g.test(codeChunks)) {
              setContiniueResponse(true);
            }
            break;
          }
          codeChunks += new TextDecoder().decode(value);
          setSingleFileCssCode(`${singleFileJsCode}${codeChunks}`);
        }
      }
    } catch { }
  };

  const handleContinueJsRespone = async () => {
    try {
      const payload = {
        project_id: projectId,
        thread_id: threadId,
        continue_response: continueResponse,
      };
      let codeChunks = "";
      const response: any = await mockupGeneratorService.generateSingleJsFile(
        payload
      );
      const reader: ReadableStreamDefaultReader<Uint8Array> | undefined =
        response.body.getReader();
      setContiniueResponse(false);
      while (true) {
        const result: any = await reader?.read();
        if (result) {
          const { done, value } = result;
          if (done) {
            if (!/```[\s\S]*```/g.test(codeChunks)) {
              setContiniueResponse(true);
            }
            break;
          }
          codeChunks += new TextDecoder().decode(value);
          setSingleFileJsCode(`${singleFileJsCode}${codeChunks}`);
        }
      }
    } catch { }
  };

  const handleJsEditorChange = (data: string) => {
    if (data) {
      setSingleFileJsCode(data);
    }
  };

  console.log("projectPageLists");
  useEffect(() => {
    if (projectPageLists && Object.keys(projectPageLists).length === 0) {
      getProjectList();
    }
  }, []);


  const changeColor = () => {
    setEnableColorChange(true);
  }
  const handleChangeColor = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const newColor = e.target.value;
    setTempColorScheme((prev: any) => ({
      ...prev,
      [key]: newColor,
    }));
  };

  const handleSaveColors = async () => {

    setIsLoadingColorChange(true)

    try {
      const payload = {
        thread_id: threadId,
        project_id: projectId,
        project_color_scheme: {
          ...projectPageLists.project.project_color_scheme,
          ...tempColorScheme
        },
      }
      const response = await mockupGeneratorService.updateProjectDetails(payload)
      const DeleteAllcodeResponse=await mockupGeneratorService.deleteAllCode({ thread_id: threadId,
        project_id: projectId,})
        const updatedProjectPageLists = {
          ...projectPageLists,
          project: {
            ...projectPageLists.project,
            project_color_scheme: {
              ...projectPageLists.project.project_color_scheme,
              ...tempColorScheme,
            },
          },
          saved_page_list: projectPageLists.saved_page_list.map((page: any) => ({
            ...page,
            html_code: null,
            css_code: null,
            js_code: null,
          })),
        };
    
        console.log("Previous projectPageLists:", projectPageLists);
        console.log("Updated projectPageLists:", updatedProjectPageLists);
    
        setProjectPageLists(updatedProjectPageLists);
        setEnableColorChange(false);
        setActivePageId(0)
    }
    catch {

    }
    finally
    {
      setIsLoadingColorChange(false)
    }
  };

  const handleCancelColorChange = () => {
    setEnableColorChange(false);

  };
  console.log(requirementApiStatus)
  return (
    <>
   

      <SearchBox />
      {enableAddPage ? (
        <div className="search-box">
          <Input
            autoFocus
            onBlur={handleAddNewProjectPage}
            className="addPageInput"
            placeholder="Write new page name here..."
            onChange={(e) => setNewPageTitleValue(e.target.value)}
          ></Input>
        </div>
      ) : enableColorChange ? (<div className="leftDownLoadBox">

        <div className="enabledColorChangeBox">

          <Tooltip placement="top" title="Primary Color">
            <Input
              type="color"
              className="colorPickerInput"
              defaultValue={projectPageLists?.project?.project_color_scheme?.navBackgroundColor}
              onChange={(e) => handleChangeColor(e, "navBackgroundColor")}
            />
          </Tooltip>
          <Tooltip placement="top" title="Secondary Color">
            <Input
              type="color"
              className="colorPickerInput"
              defaultValue={projectPageLists?.project?.project_color_scheme?.secondary_color}
              onChange={(e) => handleChangeColor(e, "secondary_color")}
            />
          </Tooltip>
          <Tooltip placement="top" title="Text Color">
            <Input
              type="color"
              className="colorPickerInput"
              defaultValue={projectPageLists?.project?.project_color_scheme?.text_color}
              onChange={(e) => handleChangeColor(e, "text_color")}
            />
          </Tooltip>
          <Tooltip placement="top" title="Table Header Color">
            <Input
              type="color"
              className="colorPickerInput"
              defaultValue={projectPageLists?.project?.project_color_scheme?.table_header_color}
              onChange={(e) => handleChangeColor(e, "table_header_color")}
            />
          </Tooltip>
          <Tooltip placement="top" title="Table Header Text Color">
            <Input
              type="color"
              className="colorPickerInput"
              defaultValue={projectPageLists?.project?.project_color_scheme?.table_header_text_color}
              onChange={(e) => handleChangeColor(e, "table_header_text_color")}
            />
          </Tooltip>
          {isLoadingColorChange?<Spin/>:
          <Tooltip placement="top" title="Save Changes">
            <CheckCircleOutlined 
              className={`colorChangeCheckIcon ${requirementApiStatus ? 'colorChangeCheckIconDisabled' : ''}`} 
              onClick={requirementApiStatus ? undefined : handleSaveColors} 
            />
          </Tooltip>
}
          <Tooltip placement="top" title="Cancel Changes">
            <CloseCircleOutlined className="colorChangeCloseIcon" onClick={handleCancelColorChange} />
          </Tooltip>
        </div>
      </div>) : (
        <div className="leftDownLoadBox">
          <Tooltip
            placement="bottom"
            title={projectPageLists?.project_details?.project_name !== undefined
              ? projectPageLists?.project_details?.project_name
              : projectPageLists?.project?.project_name
            }
          >
            <div className="fileName">
              {projectPageLists?.project_details?.project_name !== undefined
                ? projectPageLists?.project_details?.project_name
                : projectPageLists?.project?.project_name
              }
            </div>
          </Tooltip>
          <>
            <Tooltip placement="bottom" title="Add a new page Title">
              <PlusCircleFilled
                className="addPageIcon"
                onClick={() => setEnableAddPage(true)}
              />
            </Tooltip>
            <DownloadOutlined
              className={` ${projectPageLists?.saved_page_list?.some(
                (obj: any) =>
                  !obj.hasOwnProperty("html_code") ||
                  obj.html_code === null ||
                  obj.html_code === ""
              ) ||
                projectPageLists?.project?.all_css_code === null ||
                projectPageLists?.project?.all_js_code === null
                ? "downloadProjectIconDisabled"
                : "downloadProjectIcon"
                }`}
              onClick={() =>
                projectPageLists?.saved_page_list?.some(
                  (obj: any) =>
                    !obj.hasOwnProperty("html_code") ||
                    obj.html_code === null ||
                    obj.html_code === ""
                ) ||
                  projectPageLists?.project?.all_css_code === null ||
                  projectPageLists?.project?.all_js_code === null
                  ? undefined
                  : downloadZipFiles(singleFileCssCode, singleFileJsCode)
              }
            />
            <Tooltip
              placement="bottom"
              title="Amalgamate the JavaScript or CSS code into a single file.."
            >
              <Dropdown
                menu={{
                  items,
                  onClick: handleMenuClick,
                }}
                trigger={["click"]}
                disabled={projectPageLists?.saved_page_list?.some(
                  (obj: any) =>
                    !obj.hasOwnProperty("html_code") ||
                    obj.html_code === null ||
                    obj.html_code === ""
                )}
              >
                <CodeFilled
                  className={` ${projectPageLists?.saved_page_list?.some(
                    (obj: any) =>
                      !obj.hasOwnProperty("html_code") ||
                      obj.html_code === null ||
                      obj.html_code === ""
                  )
                    ? "singleFileCodeIconDisabled"
                    : "singleFileCodeIcon"
                    }`}
                />
              </Dropdown>
            </Tooltip>
            <BgColorsOutlined onClick={changeColor} />
          </>
        </div>
      )}
      <div className="nav-bar">
        <div className="nav-items">
          {projectPageLists?.saved_page_list?.map((item: any) => (
            <NavItem
              key={item.page_title}
              projectPageTitle={item.page_title}
              active={item.page_id === activePageId}
              onClick={() => handleNavItemClick(item.page_id, item.page_title)}
              pageId={item.page_id}
            />
          ))}
        </div>
      </div>
      <ViewCodeModal
        title="Css Code"
        open={cssModalopen}
        setOpen={() => setCssModalOpen(false)}
        data={singleFileCssCode}
        handleRegenerate={generateSingleCssFile1}
      >
        <CssEditor
          data={singleFileCssCode}
          onChangeProp={(e) => {
            handleCssEditorChange(e);
          }}
          index={100}
          ref={cssEditorRef}
        />
        {continueResponse && (
          <Button onClick={handleContinueCssRespone}>continue Response</Button>
        )}
      </ViewCodeModal>

      <ViewCodeModal
        title="Java Script Code"
        open={jsModalopen}
        setOpen={() => setJsModalOpen(false)}
        data={singleFileJsCode}
        handleRegenerate={generateSingleJsFile}
      >
        <JsEditor
          data={singleFileJsCode}
          onChangeProp={(e) => {
            handleJsEditorChange(e);
          }}
          index={100}
        />
        {continueResponse && (
          <Button onClick={handleContinueJsRespone}>continue Response</Button>
        )}
      </ViewCodeModal>
    </>
  );
};

export default memo(LeftNav);
