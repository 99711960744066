import { Modal } from 'antd'
import React from 'react'
import { MockupStore } from '../store/Mockup.store';
interface IModalData {
    shouldShow: boolean;
    modalHeader?: React.JSX.Element;
    modalBody?: React.JSX.Element;
    modalFooter?: React.JSX.Element;
}
const CenteredModal = () => {
    const {
        modalState,
        setModalState
      } = MockupStore((state: any) => state);
      const modalClose = () => {
        setModalState({ ...modalState, isModalOpen: false });
      };
  return (
    <Modal
        title="Vertically centered modal dialog"
        centered
        open={modalState?.isModalOpen}
        onOk={modalClose}
        onCancel={modalClose}
      >
       { modalState?.modalBody}
      </Modal>
  )
}

export default CenteredModal